
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';
import { getDashboardDataApiCall, getDashboardV2DataApiCall, getGroupBrandListApiCall, exportTransactionApiCall } from '../../services/dashboard/getDashboardDataApiCall';
import { dashboardSelectors } from '../../redux/dashboard';
import Select from 'react-select';
import { getBrandDataByFilterDashboardAction, getBrandList, getXeroInvoiceAction } from '../../redux/actions';

import DateFnsUtils from '@date-io/date-fns';
import ChartComponent from './chartComponent';
import { CLEAR_DASHBOARD_DATA, GET_DASHBOARD_DATA } from '../../redux/dashboard/actionTypes';
import '../../assets/styles/dashboard.scss'
import { FormHelperText, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Roles } from '../../utils/constants/constant';
import { notificationOpts } from '../../config';
import {
    error as errorNotificationAction,
    success as successNotificationAction
} from 'react-notification-system-redux';
import classNames from 'classnames';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import { getUploadedDate, getBranchListByBrandIdAction, getDistributors, getMerchantByBrandIdAction, getWhitelabelsData } from '../../services/newBrandBranch/checkBrandExistApiCall';
import SessionExpiryModal from '../../components/sessionExpiryModal';
import ViewLastTransactionModal from '../../components/ViewLastTransactionModal';
import { GetSessionValue } from '../../utils/sessionStorage';
import { getDetailedInfo } from '../../config/service';
import { el } from 'date-fns/locale';

export default function DashboardV2() {

    const dispatch = useDispatch();
    const Brandstate = useSelector(dashboardSelectors.getDashboardstate);
    const userstate = useSelector(dashboardSelectors.getUserstate);
    const groupBrandListstate = useSelector(dashboardSelectors.getGroupBrandListstate);
    const getDistributorsState = useSelector(newBrandBranchSelectors.getDistributorsState);
    const getUploadedDatestate = useSelector(dashboardSelectors.getUploadedDatestate);
    const getOfferTypes = useSelector(dashboardSelectors.getOfferTypes);
    useEffect(() => {
        dispatch({ type: GET_DASHBOARD_DATA, payload: { data: [], loader: true } });
    }, [])
    let defaultOption = { label: 'All', value: 'All' };
    const [filter, setFilter] = useState({});
    const [brandList, setbrandList] = useState([]);
    const [branchList, setbranchList] = useState([]);
    const [tempBrandList, setTempBrandList] = useState([]);
    const [tempBranchList, setTempBranchList] = useState([]);
    const [groupBrandList, setGroupBrandList] = useState([]);
    const [groupBrandDetails, setGroupBrandDetails] = useState([]);
    const [isInvoiceData, setIsInvoiceData] = useState(true);
    const [publisherList, setPublisherList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(defaultOption);
    const [selectedBranch, setSelectedBranch] = useState(defaultOption);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedPublisher, setSelectedPublisher] = useState(defaultOption);
    const [selectedGroupBrand, setSelectedGroupBrand] = useState(defaultOption);
    const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
    const [localLoader, setLocalLoader] = useState(false);
    const [ingestionLogDate, setIngestionLogDate] = useState({});
    const [ingestionLogList, setIngestionLogList] = useState([]);
    const [showIngestionLogList, setShowIngestionLogList] = useState(false);
    const [selectedTranCustomerType, setSelectedTranCustomerType] = useState(defaultOption);
    const [havingMultipleCustomers, setHavingMultipleCustomers] = useState(false);
    const [offerTypeList, setOfferTypeList] = useState([]);
    const [selectedOfferType, setSelectedOfferType] = useState({ label: 'All', value: 'All' });
    const [groupRequired, setGroupRequired] = useState(false)
    const isToggleDisabled = !selectedGroupBrand && !selectedBrand;
    const [offersNames, setOfferNames] = useState({});
    const [tranCustomerTypes, setTranCustomerTypes] = useState([
        { label: 'All', value: 'All' },
        { label: "New Customers", value: "NEW USER" },
        { label: "Existing Customers", value: "REPEAT USER" }
    ]);
    let cardList = [
        { defaultValue: 0, type: "text", iconType: "material", icon: "check_circle_outline", title: "Conversions", field: "conversion" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "GMV (£)", field: "revenueCount" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "Total Cashback (£)", field: "totalCost" },
        { defaultValue: 0, type: "number", iconType: "fa", icon: "fa-gbp", title: "Total Success Fee (£)", field: "totalSuccessFee" }
    ];
    const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState("");
    const [isToggleEnabled, setIsToggleEnabled] = useState(false); // State to control toggle button
    // const [startDate, setStartDate] = useState(""); // Start date for range
    // const [endDate, setEndDate] = useState(""); // End date for range
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // New state to handle the blur effect
    const [blurState, setBlurState] = useState(true);
    useEffect(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Current date with time 00:00:00

        const oneMonthBack = new Date();
        oneMonthBack.setMonth(currentDate.getMonth() - 1); // Subtract one month
        oneMonthBack.setHours(0, 0, 0, 0); // Set time to 00:00:00

        // Format the dates as "yyyy-MM-dd 00:00:00"
        const formattedEndDate = currentDate.getFullYear() + '-' +
            String(currentDate.getMonth() + 1).padStart(2, '0') + '-' +
            String(currentDate.getDate()).padStart(2, '0') + ' 23:59:59';

        const formattedStartDate = oneMonthBack.getFullYear() + '-' +
            String(oneMonthBack.getMonth() + 1).padStart(2, '0') + '-' +
            String(oneMonthBack.getDate()).padStart(2, '0') + ' 00:00:00';

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);

    }, []);
    useEffect(() => {
        getWhitelabelsData(response => {
            // Extract unique phase names
            const offersNames = Array.from(
                new Set(
                    response.flatMap(item =>
                        item.phases
                            .filter(phase => phase.phaseName) // Filter phases with a phaseName
                            .map(phase => phase.phaseName) // Extract phaseName
                    )
                )
            );
            setOfferNames(offersNames)
        })
    }, [])
    useEffect(() => {
        const initializeDashboard = async () => {
            let sessionExpired = await GetSessionValue('sessionExpired');
            if (sessionExpired && (sessionExpired === 'true' || sessionExpired === true)) {
                setSessionExpiryModal(true);
                return;
            }

            dispatch(getGroupBrandListApiCall());
            dispatch(getDistributors());
            dispatch(getUploadedDate(""));
            getBrandNameApiCall("");
            setBlurState(false); // Remove blur effect after data is loaded
        };

        initializeDashboard();

        // return () => {
        //     dispatch({ type: CLEAR_DASHBOARD_DATA });
        // };
    }, [dispatch]);
    useEffect(async () => {
        if (selectedBrand.label !== undefined && selectedBrand.label !== "All") {
            // Dispatch the action and pass the callback to handle the response
            dispatch(getMerchantByBrandIdAction(selectedBrand.value, (branchList) => {
                // Transform the response data after getting it from the callback
                let transformedData = transformData(branchList);

                // Update state based on the transformed data
                setbranchList(transformedData);
                setSelectedInvoiceNumber("");
                setSelectedMonth(null);
                setIsToggleEnabled(false);
                setSelectedBranch(defaultOption);
                Brandstate.data.invoiceList = [];
            }));
        }

    }, [selectedBrand]);
    useEffect(() => {
        setSelectedInvoiceNumber("")
        // setSelectedMonth(null)
        // setStartDate(null)
        // setEndDate(null)
        // setIsToggleEnabled(false)
        Brandstate.data.invoiceList = []
    }, [selectedBranch])
    const transformData = (data) => {
        return data.map(item => ({
            label: item.label, // You can adjust this logic to extract the appropriate label
            value: item.value,
            _id: item._id
        }));
    };


    useEffect(() => {
        if (selectedGroupBrand.label === "All") {
            setbrandList(tempBrandList);
            setbranchList(tempBranchList);
        } else if (selectedGroupBrand.label) {
            let g_Detail = groupBrandDetails.filter(a => a.groupId === selectedGroupBrand.value);
            if (g_Detail && g_Detail[0] && g_Detail[0].group_brand_ids) {
                setbrandList(g_Detail[0].group_brand_ids);
            }
        }
        setSelectedBrand(defaultOption);
        setSelectedInvoiceNumber("")
        setSelectedMonth(null)
        setIsToggleEnabled(false)
        setSelectedBranch(defaultOption)
        Brandstate.data.invoiceList = []
    }, [selectedGroupBrand]);

    useEffect(() => {
        if (groupBrandListstate.data.length) {
            let list = [{ label: 'All', value: 'All' }];
            groupBrandListstate.data.forEach(element => {
                list.push({ _id: element._id, label: element.group_name, value: element.groupId });
            });
            setGroupBrandList(list);
            setGroupBrandDetails(groupBrandListstate.data);
        } else if (groupBrandListstate.errorCode === "9001") {
            setSessionExpiryModal(true);
        }
    }, [groupBrandListstate.data]);

    useEffect(() => {
        if (getDistributorsState && getDistributorsState.data && getDistributorsState.data.distributors) {
            let list = [{ label: 'All', value: 'All' }];
            getDistributorsState.data.distributors.forEach(element => {
                if (!element.parentPublisherId) {
                    list.push({ label: element.distributors_display_name, value: element.distributors_name });
                }
            });
            setPublisherList(list);
        }
    }, [getDistributorsState.data]);

    useEffect(() => {
        console.log("Get Uploaded Date state   21  " + JSON.stringify(getUploadedDatestate))

        if (getUploadedDatestate && getUploadedDatestate.logData && getUploadedDatestate.logData.injection_data && getUploadedDatestate.logData.injection_data.length !== 0) {
            console.log("Get Uploaded Date state   2  " + JSON.stringify(getUploadedDatestate))
            setIngestionLogList(getUploadedDatestate.logData.injection_data);
        } else if (getUploadedDatestate && getUploadedDatestate.logData && getUploadedDatestate.logData.last_txn_date) {
            setIngestionLogDate(getUploadedDatestate.logData.last_txn_date);
        }
    }, [getUploadedDatestate && getUploadedDatestate.logData]);
    useEffect(() => {
        setSelectedInvoiceNumber("")
        Brandstate.data.invoiceList = [];
        setSelectedPublisher(defaultOption)
    }, [selectedMonth])

    // const handleChange=(value)=>{
    //     console.log("Distributor Namexs are    :    "+ JSON.stringify(value))
    //     const distributor_name = ['Amex', 'Amex Gold', 'Amex US Inbound']
    //     const transformedOfferTypeList = distributor_name.map(offer => ({
    //         label: offer,
    //         value: offer,
    //     }));
    //     let tempOfferTypes = [defaultOption, ...transformedOfferTypeList];
    //     setOfferTypeList(tempOfferTypes)
    // }

    const setDistributorNames = () => {
        // Transform phase names into label-value pairs
        const transformedOfferTypeList = offersNames.map(offer => ({
            label: offer,
            value: offer,
        }));

        // Prepend default option to the transformed list
        const tempOfferTypes = [defaultOption, ...transformedOfferTypeList];

        // Update the offer type list
        setOfferTypeList(tempOfferTypes);
    };



    const search = () => {
        getDashboardData(selectedInvoiceNumber, isInvoiceData);
    };

    const getDashboardData = (selectedInvoiceNumber, isInvoiceData) => {
        let conditions = {};

        if (startDate === null && endDate === null && selectedGroupBrand.label === "All" && selectedBrand.label === "All" && selectedBranch.label === "All" && selectedMonth === null && selectedPublisher.label === "All") {
            conditions = { "1": 1 }
        } else {
            if (selectedBrand.label !== "All") {
                conditions.brand_name = selectedBrand.label;
            }

            if (startDate !== null & endDate !== null) {
                conditions.txn_date_time = [startDate, endDate]
            }
            if (selectedMonth) {
                if (isInvoiceData && isToggleEnabled) {
                    conditions.month_number = new Date(selectedMonth).getMonth() + 1
                    conditions.year = new Date(selectedMonth).getFullYear()
                } else {
                    conditions.trans_month = `${new Date(selectedMonth).getFullYear()}-${(new Date(selectedMonth).getMonth() + 1).toString().padStart(2, '0')}`;
                }
            }
            if (selectedPublisher.value !== "All") {
                conditions.data_source = selectedPublisher.value;
            }
            if (userstate.role === Roles.bank && userstate.distributor_id) {
                conditions.distributor_id = userstate.distributor_id;
            }
            if (selectedGroupBrand.label !== "All") {
                conditions.group_id = selectedGroupBrand.value;
            }
            if (selectedBrand.label !== "All") {
                conditions.brand_id = selectedBrand.value;
            }
            if (selectedBranch.label !== "All") {
                conditions.kmid = selectedBranch.value;
            }
            if (isInvoiceData && selectedInvoiceNumber) {
                conditions.xero_invoice_number = selectedInvoiceNumber;
            }
            if (havingMultipleCustomers && selectedTranCustomerType.value !== "All" &&
                (selectedGroupBrand.value !== "All" || selectedBrand.value !== "All") &&
                selectedPublisher.value === "ATR") {
                conditions.tranCustomerType = selectedTranCustomerType.value;
            }
            if (offerTypeList && offerTypeList.length > 0 && selectedPublisher.value !== "All" && selectedOfferType.value !== "All") {
                // (selectedGroupBrand.label !== "All" || selectedBrand.value !== "All") &&
                // selectedPublisher.value !== "All" && selectedOfferType.value !== "All") {
                conditions.distributor_name = selectedOfferType.value;
            }

            // if(selectedOfferType!=="All"){
            //     conditions.distributors_name=selectedOfferType.value;
            // }


        }

        let request = {
            conditions_body: {
                conditions: conditions
            },
            field: []
        };

        if (isToggleEnabled) {
            if (selectedPublisher.label === "All") {
                if (selectedInvoiceNumber !== "") {
                    request.conditions_body.conditions = { "xero_invoice_number": selectedInvoiceNumber };
                } else {
                    request.conditions_body.conditions = { amount: 9999999999 };
                }
                request.conditions_body.conditions_reconciliation = conditions;
            } else {
                request.conditions_body.conditions = conditions;
                request.conditions_body.conditions_reconciliation = {};
            }
        } else {
            request.conditions_body.conditions = conditions;
            request.conditions_body.conditions_reconciliation = {};
        }

        dispatch({ type: GET_DASHBOARD_DATA, payload: { data: [], loader: true } });
        let version = "v2"
        dispatch(getDashboardV2DataApiCall(request, version));

        let getIngObj = {};
        if (conditions.data_source) {
            getIngObj.data_source = conditions.data_source;
        }
        if (selectedBrand.label === "All" && selectedGroupBrand.label !== "All") {
            getIngObj.groupId = selectedGroupBrand.value;
        }
        if (selectedBrand.label !== "All") {
            getIngObj.brandId = selectedBrand.value;
        }

        dispatch(getUploadedDate(getIngObj));
    };

    const getBrandNameApiCall = (value) => {
        dispatch(getBrandList(res => {
            if (res && res.length) {
                dispatch({ type: GET_DASHBOARD_DATA, payload: { data: [], loader: false } });
                let arr = [defaultOption, ...res];
                setbrandList(arr);
                setTempBrandList(arr);
                // getDashboardData()
            }
        }));
        console.log("getBrandNameApiCall   :   " + JSON.stringify(brandList))
    };

    const downloadExcel = () => {
        let conditions = {};

        if (selectedBrand.label !== "All") {
            conditions.brand_name = selectedBrand.label;
        }

        if (startDate !== null && endDate !== null) {
            conditions.txn_date_time = [startDate, endDate];
        }

        if (selectedMonth) {
            if (isInvoiceData && isToggleEnabled) {
                conditions.month_number = new Date(selectedMonth).getMonth() + 1;
                conditions.year = new Date(selectedMonth).getFullYear();
            } else {
                conditions.trans_month = `${new Date(selectedMonth).getFullYear()}-${(new Date(selectedMonth).getMonth() + 1).toString().padStart(2, '0')}`;
            }
        }

        if (selectedPublisher.value !== "All") {
            conditions.data_source = selectedPublisher.value;
        }

        if (userstate.role === Roles.bank && userstate.distributor_id) {
            conditions.distributor_id = userstate.distributor_id;
        }

        if (selectedGroupBrand.label !== "All") {
            conditions.group_id = selectedGroupBrand.value;
        }

        if (selectedBrand.label !== "All") {
            conditions.brand_id = selectedBrand.value;
        }

        if (offerTypeList && offerTypeList.length > 2 && selectedPublisher.value !== "All" && selectedOfferType.value !== "All") {
            // (selectedGroupBrand.label !== "All" || selectedBrand.value !== "All") &&
            // selectedPublisher.value !== "All" && selectedOfferType.value !== "All") {
            conditions.distributor_name = selectedOfferType.value;
        }
        console.log("Export Dashboard Data    :   " + JSON.stringify(userstate))
        let email = userstate.email
        let request = {
            conditions_body: {
                conditions: conditions
            },
            field: []
        };

        if (isToggleEnabled) {
            if (selectedPublisher.label === "All") {
                if (selectedInvoiceNumber !== "") {
                    request.conditions_body.conditions = { "xero_invoice_number": selectedInvoiceNumber };
                } else {
                    request.conditions_body.conditions = { amount: 9999999999 };
                }
                request.conditions_body.conditions_reconciliation = conditions;
            } else {
                request.conditions_body.conditions = conditions;
                request.conditions_body.conditions_reconciliation = {};
            }
        } else {
            request.conditions_body.conditions = conditions;
            request.conditions_body.conditions_reconciliation = {};
        }

        console.log("Export Excel from Dashboard: " + JSON.stringify(email));

        setLocalLoader(true);
        dispatch(exportTransactionApiCall(request, email, response => {
            setLocalLoader(false);
            console.log("Export Txn Response    :   " + JSON.stringify(response))
            if (response && response.responseCode === "200") {
                console.log("Export Txn Response 1   :   " + JSON.stringify(response))
                let notification = { ...notificationOpts };
                notification.message = "File exported successfully. You will receive an email with a link to download the file.";
                dispatch(successNotificationAction(notification));
            } else if (response && response.responseCode === "400") {
                console.log("Export Txn Response   2 :   " + JSON.stringify(response))
                let notification = { ...notificationOpts };
                notification.message = response.data;
                dispatch(errorNotificationAction(notification));
            }
        }));
    };

    const viewInvoice = () => {
        let data = {
            xero_invoiceNumber: selectedInvoiceNumber
        };

        if (!selectedInvoiceNumber && Brandstate?.data?.invoiceList?.length === 1) {
            data.xero_invoiceNumber = Brandstate.data.invoiceList[0].xero_invoiceNumber;
        }

        dispatch(getXeroInvoiceAction(data, res => {
            if (res && res.invoiceURL) {
                window.open(res.invoiceURL, '_blank');
            } else if (res && res.respMessage) {
                let notification = { ...notificationOpts };
                notification.message = res.respMessage;
                dispatch(errorNotificationAction(notification));
            } else {
                let notification = { ...notificationOpts };
                notification.message = "Invoice is not created.";
                dispatch(errorNotificationAction(notification));
            }
        }));
    };

    const numberWithCommas = (n) => {
        var parts = n.toString().split(".");

        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;

        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    };

    const checkInvoiceCanView = () => {
        let flag = false;
        if (Brandstate && Brandstate.data && Brandstate.data.invoiceList) {
            Brandstate.data.invoiceList.forEach(element => {
                if (element.xero_invoiceNumber === selectedInvoiceNumber && element.canView) {
                    flag = true;
                }
            });
        }
        return flag;
    };
    const handleStartDateChange = (date) => {
        console.log("Start Date   :   " + date)
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
        const formattedDate = selectedDate.getFullYear() + '-' +
            String(selectedDate.getMonth() + 1).padStart(2, '0') + '-' +
            String(selectedDate.getDate()).padStart(2, '0') + ' ' +
            '00:00:00'; // Ensure time is 00:00:00

        setStartDate(formattedDate);
        console.log("Start Date   :   " + startDate)
        if (endDate && date > endDate) {
            const selectedDate = new Date(date);
            selectedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00

            const formattedDate = selectedDate.getFullYear() + '-' +
                String(selectedDate.getMonth() + 1).padStart(2, '0') + '-' +
                String(selectedDate.getDate()).padStart(2, '0') + ' ' +
                '00:00:00'; // Ensure time is 00:00:00
            setStartDate(formattedDate);
            // setEndDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        console.log("Start Date   :   " + date)
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00

        const formattedDate = selectedDate.getFullYear() + '-' +
            String(selectedDate.getMonth() + 1).padStart(2, '0') + '-' +
            String(selectedDate.getDate()).padStart(2, '0') + ' ' +
            ' 23:59:59'; // Ensure time is 00:00:00

        setEndDate(formattedDate);
        console.log("Start Date   :   " + startDate)
    };

    // const handleStartDateChange = (date) => {
    //     setStartDate(new Date(date).toISOString().replace('T', ' ').substring(0, 19));
    // };

    // const handleEndDateChange = (date) => {
    //     setEndDate(new Date(date).toISOString().replace('T', ' ').substring(0, 19));
    // };

    const handleToggleChange = () => {
        if (!selectedGroupBrand && !selectedBrand) {
            alert("Please select a Group or Brand before proceeding.");
            return;
        }
        setSelectedInvoiceNumber("")
        setSelectedMonth(null)
        // setIsInvoiceData(!isInvoiceData);
        setStartDate(null)
        setEndDate(null)
        setIsToggleEnabled(!isToggleEnabled);
    };
    console.log("Brand State    :    " + JSON.stringify(Brandstate.data))
    return (
        <>
            {(Brandstate.loader || localLoader) &&
                <div className="loading">Loading...</div>
            }
            <div className={classNames('dashboard-container', { 'blur-ui': blurState })}>
                <div className="heading m-3">
                    <div className="row my-3">
                        <div className="col-md-5 d-flex align-items-center">
                            <h2 className="m-0">
                                <span>
                                    <Icon className="heading-icon">adjust</Icon>
                                </span>{' '}
                                Dashboard
                            </h2>
                        </div>
                        <div className="col-md-7 invoice-data-toogle align-items-right">
                            <div className="toggle-button-cover">
                                <div className="button-cover">
                                    <div className={`button r ${isToggleEnabled ? 'on' : 'off'}`} id="button-1">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={isToggleEnabled}
                                            // disabled={selectedMonth}
                                            onChange={handleToggleChange}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='title'>Invoiced Data</div>
                            {(selectedGroupBrand || selectedBrand || selectedBranch) &&
                                Brandstate.data &&
                                Brandstate.data.invoiceList &&
                                Brandstate.data.invoiceList.length > 0 &&
                                <>
                                    <select
                                        name="invoice_number"
                                        className="form-control dropdown inv-no-dropdown"
                                        value={selectedInvoiceNumber || ""}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            console.log("Invoice Number change: " + selectedValue);
                                            setSelectedInvoiceNumber(selectedValue);
                                            getDashboardData(selectedValue, isInvoiceData);
                                        }}
                                    >
                                        <option value="">Select Invoice</option>
                                        {Brandstate.data.invoiceList.map((res1, index) => (
                                            <option key={index} value={res1.xero_invoiceNumber}>
                                                {res1.xero_invoiceNumber}{" "}
                                                {res1.xero_invoiceNumber !== "All" &&
                                                    (res1.invoice_status === "Approved" ? "(Approved)" : "(Draft)")}
                                            </option>
                                        ))}
                                    </select>

                                    {((selectedInvoiceNumber && selectedInvoiceNumber !== "All") || (Brandstate?.data?.invoiceList?.length === 1)) && checkInvoiceCanView() &&
                                        <div className='title m-l-10'>
                                            <Icon style={{ fontSize: "2rem" }} className="text-info link-pointer-icon" data-toggle="tool-tip"
                                                onClick={() => { viewInvoice(); }} title="View Invoice">visibility</Icon>
                                        </div>
                                    }


                                    {((selectedInvoiceNumber && selectedInvoiceNumber !== "All") || (Brandstate?.data?.invoiceList?.length === 1)) && checkInvoiceCanView() &&
                                        <div className='title m-l-10'>
                                            <Icon style={{ fontSize: "2rem" }} className="text-info link-pointer-icon" data-toggle="tool-tip"
                                                onClick={() => { viewInvoice(); }} title="View Invoice">visibility</Icon>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-wrap align-items-center col-12 override-m-p my-3" style={{ marginBottom: '2rem !important' }}>
                    {/* Group Brand Filter */}
                    <div className="col-md-2 dashboard-filter-box px-2">
                        {groupBrandList.length > 0 && (
                            <div className="form-group w-100">
                                <label>Group</label>
                                <Select
                                    value={selectedGroupBrand}
                                    className="w-100 basic-multi-select"
                                    cacheOptions
                                    isClearable
                                    options={groupBrandList}
                                    required={groupRequired}
                                    onChange={(value, e) => {
                                        if (e.action === "select-option") {
                                            setSelectedGroupBrand(value);
                                        }
                                        if (e.action === "clear") {
                                            setSelectedGroupBrand(defaultOption);
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* Brand Filter */}
                    <div className="col-md-2 dashboard-filter-box px-2">
                        {brandList.length > 0 && (
                            <div className="form-group w-100">
                                <label>Brand</label>
                                <Select
                                    value={selectedBrand}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={brandList}
                                    onChange={(value, e) => {
                                        if (e.action === "select-option") {
                                            setSelectedBrand(value);
                                        }
                                        if (e.action === "clear") {
                                            setSelectedBrand(defaultOption);
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    {/* Branch Filter */}
                    {selectedBrand && selectedBrand.value !== "All" && (
                        <div className="col-md-2 dashboard-filter-box px-2">
                            {branchList.length > 0 && (
                                <div className="form-group w-100">
                                    <label>Branch</label>
                                    <Select
                                        value={selectedBranch}
                                        className="w-100 basic-multi-select"
                                        defaultValue={{ label: 'All', value: 'All' }}
                                        cacheOptions
                                        isClearable
                                        options={branchList}
                                        onChange={(value, e) => {
                                            if (e.action === "select-option") {
                                                setSelectedBranch(value);
                                            }
                                            if (e.action === "clear") {
                                                setSelectedBranch(defaultOption);
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}

                    {/* Customer Type Filter */}
                    {havingMultipleCustomers && (selectedGroupBrand.value !== "All" || selectedBrand.value !== "All") && selectedPublisher.value === "ATR" && (
                        <div className="col-md-3 dashboard-filter-box px-2">
                            <div className="form-group w-100">
                                <label>Customer Type</label>
                                <Select
                                    value={selectedTranCustomerType}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={tranCustomerTypes}
                                    onChange={(value, e) => {
                                        if (e.action === "select-option") {
                                            setSelectedTranCustomerType(value);
                                        }
                                        if (e.action === "clear") {
                                            setSelectedTranCustomerType(defaultOption);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {/* Date Range Filter */}
                    {!isToggleEnabled && (
                        <div className="col-md-4 dashboard-filter-box px-2 invoice-info">
                            <div className="form-group w-100">
                                <label>Date Range</label>
                                <div className="d-flex">

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            className="mr-2"
                                            inputVariant="outlined"
                                            value={startDate}
                                            onChange={handleStartDateChange}
                                            format="yyyy-MM-dd"
                                            label="Start Date"
                                            maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                            clearable
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => setStartDate(null)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                        <DatePicker
                                            inputVariant="outlined"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            format="yyyy-MM-dd"
                                            label="End Date"
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            clearable
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => setEndDate(null)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </div>
                            </div>
                        </div>
                    )}

                    {/* Invoice Month Filter */}
                    {isToggleEnabled && (
                        <div className="col-md-3 dashboard-filter-box px-2 invoice-info">
                            <div className="form-group w-100" >
                                <label>Invoice Month</label>
                                {isInvoiceData && (
                                    <span className="infoicon" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}>
                                        <Icon style={{ fontSize: "2rem" }} className="text-info" title="Data from previous month may be included in invoice.">
                                            info
                                        </Icon>
                                    </span>
                                )}
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        inputFormat="yyyy-MM"
                                        views={['year', 'month']}
                                        value={selectedMonth}
                                        clearable
                                        required={isToggleEnabled}
                                        maxDate={new Date()}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={() => setSelectedMonth(null)}>
                                                    <ClearIcon />
                                                </IconButton>
                                            )
                                        }}
                                        onChange={(value) => {
                                            setSelectedMonth(value);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            {/* {isToggleEnabled && !selectedMonth && (
                                    <FormHelperText>Month is required</FormHelperText>
                                )} */}
                        </div>
                    )}

                    {/* Publisher Filter */}
                    {userstate.role !== Roles.bank && (
                        <div className={isToggleEnabled ? 'col-md-3 dashboard-filter-box px-2' : 'col-md-2 dashboard-filter-box px-2'}>
                            <div className="form-group w-100">
                                <label>Publisher</label>
                                <Select
                                    value={selectedPublisher}
                                    className="w-100 basic-multi-select"
                                    defaultValue={{ label: 'All', value: 'All' }}
                                    cacheOptions
                                    isClearable
                                    options={publisherList}
                                    onChange={(value, e) => {

                                        // Handle specific actions
                                        if (e.action === "select-option") {
                                            setSelectedPublisher(value); // Update selected publisher
                                            setOfferTypeList([]); // Reset offer type list
                                            setSelectedOfferType(defaultOption); // Reset selected offer type

                                            if (value?.value === "Amex") {
                                                setDistributorNames(); // Apply custom logic for "Amex"
                                            }

                                            // Uncomment if dashboard data refresh is needed
                                            // getDashboardData();
                                        }

                                        if (e.action === "clear") {
                                            setSelectedPublisher(defaultOption); // Reset publisher to default
                                            setOfferTypeList([]); // Clear offer type list
                                            setSelectedOfferType(defaultOption); // Reset selected offer type

                                            // Uncomment if dashboard data refresh is needed
                                            // getDashboardData();
                                        }
                                    }}

                                />
                            </div>
                        </div>
                    )}

                    {/* Search Button */}
                    <div className="col-md-1 d-flex align-items-center justify-content-center px-2">
                        <button
                            className="btn btn-sm btn-primary w-100"
                            disabled={isToggleEnabled && !selectedMonth}
                            onClick={search}
                        >
                            Search
                        </button>
                    </div>

                    {/* Export Button */}
                    <div className="col-md-1 d-flex align-items-center justify-content-center px-2">
                        <button
                            className="btn btn-sm btn-primary w-100"
                            disabled={
                                (isToggleEnabled && !selectedMonth)
                                || (selectedGroupBrand?.label === "All" && selectedBrand?.label === "All")
                            }
                            onClick={downloadExcel}
                        >
                            Export
                        </button>
                    </div>
                </div>
                {/* Offer Type Filter */}
                {selectedPublisher.value !== "All" && offerTypeList && offerTypeList.length > 2 && (
                    <div className="col-md-2 dashboard-filter-box px-2">
                        <div className="form-group w-100">
                            <label>Offer Type</label>
                            <Select
                                value={selectedOfferType}
                                className="w-100 basic-multi-select"
                                defaultValue={{ label: 'All', value: 'All' }}
                                cacheOptions
                                isClearable
                                options={offerTypeList}
                                onChange={(value, e) => {
                                    if (e.action === "select-option") {
                                        setSelectedOfferType(value);
                                    }
                                    if (e.action === "clear") {
                                        setSelectedOfferType(defaultOption);
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="row justify-content-center">
                    {cardList.map(res => {
                        return <div className="col-xl-2 col-lg-2" key={res.title}>
                            <div className="card pill">
                                <div className={classNames("card-body text-center pr-0 pl-0")}
                                // { 'blurText': Brandstate.data[res.field] === 0 }, { 'blurText': (["totalCost", "totalSuccessFee"].includes(res.field) && selectedBrand.label === "All" && selectedGroupBrand.label === "All") })}
                                >
                                    <div>
                                        <div className="icon-with-bg">
                                            {res.iconType === 'material' ? <Icon className="text-primary" fontSize="inherit">{res.icon}</Icon>
                                                : <i className={`text-primary fa ${res.icon}`} ></i>}
                                        </div>
                                        {res.type === "number" ?
                                            <p className="bold">{Brandstate.data[res.field] ? numberWithCommas(Brandstate.data[res.field].toFixed(2)) :
                                                numberWithCommas(res.defaultValue)}</p>
                                            :
                                            <p className="bold">{Brandstate.data[res.field] ? numberWithCommas(Brandstate.data[res.field]) :
                                                numberWithCommas(res.defaultValue)}</p>
                                        }

                                        <p>{res.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })}

                    <div className="col-xl-2 col-lg-2">
                        <div className="card pill">
                            <div className={classNames("card-body text-center pr-0 pl-0")}>
                                {selectedPublisher.value !== "All" &&
                                    <div className='upload-date-box'>
                                        <div className="icon-with-bg">
                                            <Icon className="text-primary" fontSize="inherit">today</Icon>
                                        </div>
                                        {(ingestionLogDate && ingestionLogDate[0] && ingestionLogDate[0].last_txn_time) ?
                                            <p className="bold title m-t-15">
                                                Last Transaction: {new Date(ingestionLogDate[0].last_txn_time).toISOString().split('T')[0]}
                                            </p>
                                            :
                                            <p className="bold title m-t-15">
                                                Last Transaction: <strong>NA</strong>
                                            </p>
                                        }
                                    </div>
                                }

                                {selectedPublisher.value === "All" &&
                                    <div className='upload-date-box'>
                                        <div className="icon-with-bg">
                                            <Icon className="text-primary" fontSize="inherit">today</Icon>
                                        </div>
                                        <div className="bold title m-t-15 c-pointer" onClick={() => setShowIngestionLogList(true)}>
                                            View Last Transaction
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <ChartComponent filter={filter} />

                <ViewLastTransactionModal close={() => setShowIngestionLogList(false)}
                    ingestionLogList={ingestionLogList}
                    show={showIngestionLogList} />

                <SessionExpiryModal isOpen={sessionExpiryModal} />
            </div >
        </>
    );
}

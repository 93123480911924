
// import React, { useEffect } from "react";
// import { Button, TextField, Typography, Box, Grid } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import dayjs from "dayjs";
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// dayjs.extend(isSameOrBefore);
// dayjs.extend(isSameOrAfter);

// // Days of the week array
// const daysOfWeek = [
//     { label: "M", value: "Mon" },
//     { label: "T", value: "Tue" },
//     { label: "W", value: "Wed" },
//     { label: "T", value: "Thu" },
//     { label: "F", value: "Fri" },
//     { label: "S", value: "Sat" },
//     { label: "S", value: "Sun" }
// ];

// const DayPicker = ({ selectedDays, setSelectedDays, selectedTimeSlots, setSelectedTimeSlots, onSelectedDaysChange, initialSelectedDays }) => {
//     console.log("Day Picker    :   " + JSON.stringify(selectedTimeSlots))
//     // Toggle a day's selection and add/remove its time slot
//     const toggleDay = (day) => {
//         setSelectedDays((prev) => {
//             const isDaySelected = prev.includes(day);
//             if (isDaySelected) {
//                 setSelectedTimeSlots((prevSlots) => prevSlots.filter((slot) => slot.day !== day));
//                 return prev.filter((d) => d !== day);
//             } else {
//                 setSelectedTimeSlots((prevSlots) => [...prevSlots, { day, start_time: null, end_time: null }]);
//                 return [...prev, day];
//             }
//         });
//     };

//     // Handle time change for a specific day and time type (start or end)
//     const handleTimeChange = (day, type, newValue) => {
//         setSelectedTimeSlots((prev) => {
//             return prev.map((slot) =>
//                 slot.day === day
//                     ? { ...slot, [type]: newValue ? dayjs(newValue).format("HH:mm") : null }
//                     : slot
//             );
//         });
//     };

//     // Populate output for parent component when selectedDays or selectedTimeSlots change
//     useEffect(() => {
//         const output = selectedDays.map((day) => {
//             const slot = selectedTimeSlots.find((s) => s.day === day) || { start_time: null, end_time: null };
//             return {
//                 day,
//                 start_time: slot.start_time,
//                 end_time: slot.end_time
//             };
//         });
//         onSelectedDaysChange(output);
//     }, []);

//     return (
//         <div style={{ paddingTop: "20px" }}>
//             <Typography variant="h6" style={{ marginBottom: '10px' }}>Select Days of the Week</Typography>
//             <Box sx={{ display: "flex", justifyContent: "space-between", maxWidth: "200px", marginBottom: '20px' }}>
//                 {daysOfWeek.map((day) => (
//                     <Button
//                         key={day.value}
//                         variant={selectedDays.includes(day.value) ? "contained" : "outlined"}
//                         onClick={() => toggleDay(day.value)}
//                         style={{
//                             minWidth: "30px",
//                             borderRadius: "50%",
//                             margin: "5px",
//                         }}
//                     >
//                         {day.label}
//                     </Button>
//                 ))}
//             </Box>

//             {selectedDays.map((day) => {
//                 // const timeSlot = selectedTimeSlots.find((slot) => slot.day === day) || { start_time: null, end_time: null };
//                 const timeSlot = Array.isArray(selectedTimeSlots)
//                     ? selectedTimeSlots.find((slot) => slot.day === day) || { start_time: null, end_time: null }
//                     : { start_time: null, end_time: null };
//                 return (
//                     <Grid container spacing={2} key={day} sx={{ alignItems: 'center', marginBottom: 2 }}>
//                         <Grid item xs={2}>
//                             <Typography>{day}</Typography>
//                         </Grid>
//                         <LocalizationProvider dateAdapter={AdapterDateFns}>
//                             <Grid item xs={5}>
//                                 <TimePicker
//                                     label={`${day} Start Time`}
//                                     ampm={false}
//                                     value={timeSlot.start_time ? new Date(`1970-01-01T${timeSlot.start_time}:00`) : null}
//                                     onChange={(newValue) => handleTimeChange(day, 'start_time', newValue)}
//                                     slots={{
//                                         textField: (params) => <TextField {...params} fullWidth />
//                                     }}
//                                 />
//                             </Grid>
//                             <Grid item xs={5}>
//                                 <TimePicker
//                                     label={`${day} End Time`}
//                                     ampm={false}
//                                     value={timeSlot.end_time ? new Date(`1970-01-01T${timeSlot.end_time}:00`) : null}
//                                     onChange={(newValue) => handleTimeChange(day, 'end_time', newValue)}
//                                     slots={{
//                                         textField: (params) => <TextField {...params} fullWidth />
//                                     }}
//                                 />
//                             </Grid>
//                         </LocalizationProvider>

//                     </Grid>
//                 );
//             })}
//         </div>
//     );
// };

// export default DayPicker;





// DayPicker.js
// import React, { useEffect } from "react";
// import { Button, TextField, Typography, Box, Grid } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import dayjs from "dayjs";

// // Days of the week array
// const daysOfWeek = [
//     { label: "M", value: "Mon" },
//     { label: "T", value: "Tue" },
//     { label: "W", value: "Wed" },
//     { label: "T", value: "Thu" },
//     { label: "F", value: "Fri" },
//     { label: "S", value: "Sat" },
//     { label: "S", value: "Sun" }
// ];

// const DayPicker = ({ selectedDays, setSelectedDays, selectedTimeSlots, setSelectedTimeSlots, onSelectedDaysChange }) => {
//     // Toggle a day's selection and add/remove its time slot
//     console.log("Day Picker    :   "+JSON.stringify(selectedDays)+"   :    "+JSON.stringify(selectedTimeSlots))
//     const toggleDay = (day) => {
//         setSelectedDays((prev) => {
//             const isDaySelected = prev.includes(day);
//             if (isDaySelected) {
//                 setSelectedTimeSlots((prevSlots) => prevSlots.filter((slot) => slot.day !== day));
//                 return prev.filter((d) => d !== day);
//             } else {
//                 setSelectedTimeSlots((prevSlots) => [...prevSlots, { day, start_time: null, end_time: null }]);
//                 return [...prev, day];
//             }
//         });
//     };

//     // Handle time change for a specific day and time type (start or end)
//     const handleTimeChange = (day, type, newValue) => {
//         setSelectedTimeSlots((prev) => {
//             return prev.map((slot) =>
//                 slot.day === day
//                     ? { ...slot, [type]: newValue ? dayjs(newValue).format("HH:mm") : null }
//                     : slot
//             );
//         });
//     };

//     // Populate output for parent component when selectedDays or selectedTimeSlots change
//     // useEffect(() => {
//     //     const output = selectedDays.map((day) => {
//     //         const slot = selectedTimeSlots.find((s) => s.day === day) || { start_time: null, end_time: null };
//     //         return {
//     //             day,
//     //             start_time: slot.start_time,
//     //             end_time: slot.end_time
//     //         };
//     //     });
//     //     onSelectedDaysChange(output);
//     // }, [selectedDays, selectedTimeSlots, onSelectedDaysChange]);

//     return (
//         <div style={{ paddingTop: "20px" }}>
//             <Typography variant="h6" style={{ marginBottom: '10px' }}>Select Days of the Week</Typography>
//             <Box sx={{ display: "flex", justifyContent: "space-between", maxWidth: "200px", marginBottom: '20px' }}>
//                 {daysOfWeek.map((day) => (
//                     <Button
//                         key={day.value}
//                         variant={selectedDays.includes(day.value) ? "contained" : "outlined"}
//                         onClick={() => toggleDay(day.value)}
//                         style={{
//                             minWidth: "30px",
//                             borderRadius: "50%",
//                             margin: "5px",
//                         }}
//                     >
//                         {day.label}
//                     </Button>
//                 ))}
//             </Box>

//             {/* {selectedDays.map((day) => {
//                 const timeSlot = selectedTimeSlots.find((slot) => slot.day === day) || { start_time: null, end_time: null };
//                 return (
//                     <Grid container spacing={2} key={day} sx={{ alignItems: 'center', marginBottom: 2 }}>
//                         <Grid item xs={2}>
//                             <Typography>{day}</Typography>
//                         </Grid>
//                         <LocalizationProvider dateAdapter={AdapterDateFns}>
//                             <Grid item xs={5}>
//                                 <TimePicker
//                                     label={`${day} Start Time`}
//                                     ampm={false}
//                                     value={timeSlot.start_time ? new Date(`1970-01-01T${timeSlot.start_time}:00`) : null}
//                                     onChange={(newValue) => handleTimeChange(day, 'start_time', newValue)}
//                                     slotProps={{
//                                         textField: { fullWidth: true }
//                                     }}
//                                 />
//                             </Grid>
//                             <Grid item xs={5}>
//                                 <TimePicker
//                                     label={`${day} End Time`}
//                                     ampm={false}
//                                     value={timeSlot.end_time ? new Date(`1970-01-01T${timeSlot.end_time}:00`) : null}
//                                     onChange={(newValue) => handleTimeChange(day, 'end_time', newValue)}
//                                     slotProps={{
//                                         textField: { fullWidth: true }
//                                     }}
//                                 />
//                             </Grid>
//                         </LocalizationProvider>
//                     </Grid>
//                 );
//             })} */}
//         </div>
//     );
// };

// export default DayPicker;


import React, { useEffect } from "react";
import { Button, TextField, Typography, Box, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

// Days of the week array
const daysOfWeek = [
    { label: "M", value: "Mon" },
    { label: "T", value: "Tue" },
    { label: "W", value: "Wed" },
    { label: "T", value: "Thu" },
    { label: "F", value: "Fri" },
    { label: "S", value: "Sat" },
    { label: "S", value: "Sun" }
];

const DayPicker = ({ selectedTimeSlots, setSelectedTimeSlots, onSelectedDaysChange }) => {
    // Extract selectedDays from selectedTimeSlots
    const selectedDays = selectedTimeSlots.map((slot) => slot.day);
    console.log("Selected Time Slots    :   " + JSON.stringify(selectedTimeSlots));

    // Toggle a day's selection and add/remove its time slot
    const toggleDay = (day) => {
        setSelectedTimeSlots((prevSlots) => {
            const isDaySelected = prevSlots.some((slot) => slot.day === day);
            if (isDaySelected) {
                return prevSlots.filter((slot) => slot.day !== day);
            } else {
                return [...prevSlots, { day, start_time: null, end_time: null }];
            }
        });
    };

    // Handle time change for a specific day and time type (start or end)
    const handleTimeChange = (day, type, newValue) => {
        // Ensure newValue is converted to a dayjs instance
        const newDayjsValue = dayjs(newValue);
        setSelectedTimeSlots((prev) => {
            return prev.map((slot) =>
                slot.day === day
                    ? {
                          ...slot,
                          [type]: newDayjsValue.isValid() ? newDayjsValue.format("HH:mm") : null,
                      }
                    : slot
            );
        });
    };

    // Notify parent component of changes to selected days and times
    useEffect(() => {
        onSelectedDaysChange(selectedTimeSlots);
    }, [selectedTimeSlots, onSelectedDaysChange]);

    return (
        <div style={{ paddingTop: "20px" }}>
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
                Select Days of the Week
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", maxWidth: "200px", marginBottom: "20px" }}>
                {daysOfWeek.map((day) => (
                    <Button
                        key={day.value}
                        variant={selectedDays.includes(day.value) ? "contained" : "outlined"}
                        onClick={() => toggleDay(day.value)}
                        style={{
                            minWidth: "30px",
                            borderRadius: "50%",
                            margin: "5px",
                        }}
                    >
                        {day.label}
                    </Button>
                ))}
            </Box>

            {selectedTimeSlots.map((slot) => (
                <Grid container spacing={2} key={slot.day} sx={{ alignItems: "center", marginBottom: 2 }}>
                    <Grid item xs={2}>
                        <Typography>{slot.day}</Typography>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={5}>
                            <TimePicker
                                label={`${slot.day} Start Time`}
                                ampm={false}
                                value={slot.start_time ? dayjs(`1970-01-01T${slot.start_time}`) : null}
                                onChange={(newValue) => handleTimeChange(slot.day, "start_time", newValue)}
                                slotProps={{
                                    textField: { fullWidth: true },
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TimePicker
                                label={`${slot.day} End Time`}
                                ampm={false}
                                value={slot.end_time ? dayjs(`1970-01-01T${slot.end_time}`) : null}
                                onChange={(newValue) => handleTimeChange(slot.day, "end_time", newValue)}
                                slotProps={{
                                    textField: { fullWidth: true },
                                }}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
            ))}
        </div>
    );
};

export default DayPicker;


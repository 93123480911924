import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import ModalComponent from '../../components/Modal'
import CampaignForm, { CampaignFormInitialData } from '../../components/CreateCampaign/CampaignForm'
import CampaignSummary from '../../components/CreateCampaign/CampaignSummary'
import TnCComponent from './TnC'
import {
  createCampaignAction,
  editCampaignAction,
  setCampaignForEditAction,
  getCurrentCampaignCountAction,
  getEntityForSelectAction,
  setUserAction,
  getProjectionsForUserAction,
  getProjectionsForUserActionTrain,
  getEntityDetails,
  getCampaignNameAction,
  getManageCreativeAction,
  setPreviewDataAction,
  getEntityAction,
  saveCsvAction,
  saveinitialValuesAction,
  settingsAction,
  clearCampaignTemplateData,
  saveTabData,
  updateUserProfileAction,
  loadBrandData,
  setSettingsData
} from '../../redux/actions'
import Icon from '@material-ui/core/Icon'
import { Redirect } from "react-router-dom";
import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import { Prompt } from 'react-router-dom'
import config from "../../config/config";
import Loader from './../../components/Loader'
//session expiry modal
import SessionExpiryModal from '../../components/sessionExpiryModal'
import { DeleteSessionValue, GetSessionValue } from "../../utils/sessionStorage";
class CreateCampaign extends Component {
  state = {
    campaignFormData: CampaignFormInitialData,
    showTCModal: true,
    createCount: 0,
    merchantIds: [],
    formType: 'add',
    sessionExpiryModal: false,
    loginRedirect: false,
    merchantsValid: false,
    documents: [],
    previewData: '',
    merchantIdList: '',
    percentageData: '',
    formValues: '',
    languageData: '',
    selectedMerchants: [],
    previewRoute: false,
    isLoading: false,
    isFirstLoginModal: false,
    branchRoute: false,
    submitType: 'create',
    summaryValues: {},
    isApproved: false,
    branchesPendingModal: false,
    trainComponent: false,
    brandData: '',
    weekDaysArray: [
      {
        "label": 'MON',
        "value": 'MON',
        "selected": true,
      },
      {
        "label": 'TUE',
        "value": 'TUE',
        "selected": true,
      },
      {
        "label": 'WED',
        "value": 'WED',
        "selected": true,
      },
      {
        "label": 'THU',
        "value": 'THU',
        "selected": true,
      },
      {
        "label": 'FRI',
        "value": 'FRI',
        "selected": true,
      },
      {
        "label": 'SAT',
        "value": 'SAT',
        "selected": false,
      },
      {
        "label": 'SUN',
        "value": 'SUN',
        "selected": false,
      },
    ],
    selectedDays: [
      {
        "label": 'MON',
        "value": 'MON',
      },
      {
        "label": 'TUE',
        "value": 'TUE',
      },
      {
        "label": 'WED',
        "value": 'WED',
      },
      {
        "label": 'THU',
        "value": 'THU',
      },
      {
        "label": 'FRI',
        "value": 'FRI',
      },
    ],
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
    isCampaignCreated: false,
    settingsState: '',
  }
  constructor(props) {
    super(props)
    let { campaignForEdit, previewData } = props
    if (campaignForEdit || (props && props.location && props.location.isPreview)) {
      this.state.formType = 'edit'
      let editableData = {}
      if (campaignForEdit) {
        editableData = campaignForEdit
      } else {
        editableData = previewData
      }
      let campaignFormData = { ...CampaignFormInitialData }
      campaignFormData._id = editableData._id
      campaignFormData.name = editableData.name
      campaignFormData.customerType = editableData.customerType
      campaignFormData.discountType = editableData.discountType || 'dp'
      campaignFormData.successFee = editableData.successFee
      campaignFormData.start_time = editableData.start_time
      campaignFormData.end_time = editableData.end_time
      campaignFormData.suggestions = editableData.suggestions
      campaignFormData.recurring = editableData.recurring
      if (editableData.start_date) {
        campaignFormData.start_date = new Date(editableData.start_date)
      } else if (editableData.openingTime) {
        campaignFormData.start_date = new Date(editableData.openingTime)
      }
      campaignFormData.merchantId = editableData.merchantId
      if (editableData.budget) {
        campaignFormData.budget = editableData.budget;
        campaignFormData.budgetType = 'budget'
      } else {
        campaignFormData.budgetType = 'na'
      }
      switch (editableData.discountType) {
        case 'dp': {
          if (editableData.discountPercent)
            campaignFormData.discountPercent = editableData.discountPercent
          break;
        }
        case 'dv': {
          campaignFormData.discountAmount = editableData.discountAmount
          campaignFormData.discountValue = editableData.discountValue
          break;
        }
        case 'ss': {
          campaignFormData.discountSpreadAmount = editableData.discountSpreadAmount
          campaignFormData.discountSpreadValue = editableData.discountSpreadValue
          break;
        }
        default: { }
      }
      if (editableData.customerType == 'existing') {
        campaignFormData.lastTransaction = editableData.lastTransaction
      }
      this.state.campaignFormData = campaignFormData
    }
  }
  campaignSummaryData = null

  componentDidMount = async () => {
    console.log('modalvisible-----------------',this.state.modalVisible)
    console.log('props-----------------',this.props)

    let {
      campaignForEdit,
      history,
      getCurrentCampaignCount,
      user,
      currentCampaignCount,
      getEntityForSelect,
      getProjectionsForUser,
      getDetails,
      getCampaignName,
      getManageCreative,
      location,
      previewData,
      match,
      getEntityData,
      saveCsv,
      saveInitialValues,
      getSettingsData,
      clearTemplateData,
      loadBrandsData,
      loadSettingsData,
      setTabs
    } = this.props
    await getSettingsData({}, async (res) => {
      await loadSettingsData(res)
      await this.setState({
        settingsState: res
      })
    })
    let checkUser = GetSessionValue('user')
    console.log('merchantidsform--------------------',this.state.merchantIds)
    console.log(checkUser)
    if (checkUser) {
      console.log('merchantidsform--------------------',this.state.merchantIds)
      let sessionExpiryModal = false
      let sessionExpired = await GetSessionValue('sessionExpired')
      console.log(sessionExpired)
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      await this.setState({ sessionExpiryModal: sessionExpiryModal, branchRoute: false })
      if (sessionExpiryModal) {
        return
      }
      let { selectedMerchants } = this.state
      let userDetails = GetSessionValue('user')
      this.setState({ isLoading: true })
      if (userDetails) {
        console.log('user details-----------',userDetails)
        await getProjectionsForUser(userDetails.brandObjId, async (res) => {
          loadBrandsData(res)
          await this.setState({ brandData: res })
        })
      }
      
      //To append campaign name on creation
      if (!campaignForEdit) {
        console.log('campaignforedit-------------')
        getCampaignName((res, type) => {
          if (type == 'success') {
            let data = this.state.campaignFormData
            data.name = res.campaignName
            this.setState({ campaignFormData: data, isApproved: res.approval })
          }
        })
        //Campaign Images
        // getManageCreative('', (res, type) => {
        //   if(type=='success'){
        //     let documents=[]
        //     res.forEach((item,index)=>{
        //       documents.push({
        //       label:<div className='text-center'><img src={`${config.imgUrl}docs/${item.fileName}`} className='dropdown__img' /></div>,
        //         value:item.fileName
        //       })
        //     })
        //     this.setState({documents:documents})
        //   }
        // })
      }
      if (previewData.weekDays && previewData.weekDays.length > 0) {
        await this.setState({ weekDaysArray: previewData.weekDays })
      }
      if (campaignForEdit && campaignForEdit.weekDays && campaignForEdit.weekDays.length > 0) {
        let selectedDays = [];
        for (let i = 0; i < campaignForEdit.weekDays.length; i++) {
          if (campaignForEdit.weekDays[i]['selected']) {
            selectedDays.push({
              "label": campaignForEdit.weekDays[i].label,
              "value": campaignForEdit.weekDays[i].label,
            })
          }
        }
        await this.setState({ weekDaysArray: campaignForEdit.weekDays, selectedDays: selectedDays })
        console.log(selectedDays)
      }
      console.log('hiiiiiiiiiiiiiii')
      //  if(sessionExpired!='true'){
      //Get userdetails for setting merchantids of useer
      await getEntityData(null, null, {}, 'merchants', 'multiselect', async (response, pagination) => {
        let merchantIds = []
        if (response && response.length > 0 && typeof (response) == 'object') {
          response.forEach((item, index) => {
            item.value = item;
            item.label = item.name
            merchantIds.push(item)
          })
          await this.setState({ merchantIds: merchantIds })
        }
        if (merchantIds && merchantIds.length == 0) {

          let sessionAbout = await GetSessionValue('sessionExpired')
          if (sessionAbout == 'false' || sessionAbout == false) {
            await this.setState({ isFirstLoginModal: true })
          } else if (sessionAbout == 'true' || sessionAbout == true) {
            await this.setState({ isFirstLoginModal: false, sessionExpiryModal: true })
          } else {
            await this.setState({ isFirstLoginModal: true, sessionExpiryModal: false })
          }
        }
      })
      //preview campaign to edit
      if (!campaignForEdit && !(location && location.isPreview)) {
        //to clear csv data
        saveCsv(null)
        //to clear initial values
        saveInitialValues({
          sms: {
            validFor: 'one',
            'Landing Page': '',
            'Valid For': 'one'
          },
          email: {
            validFor: 'one',
            'landing Page': '',
            'Valid For': 'one'
          },
          app: {
            validFor: 'one',
            'Landing Page': '',
            'Valid For': 'one'
          },
        })
        clearTemplateData()
        setTabs(null)
        if (match.url.search('edit') !== -1) {
          history.push('/user/campaigns/create')
        } else {
          let m = moment()
          let campaignName = user.entity ? `${user.entity.name}${m.format('YYMM')}${currentCampaignCount + 1}` : `${m.format('YYMM')}${currentCampaignCount + 1}`
          let campaignFormData = { ...CampaignFormInitialData, campaignName }
          if (!campaignFormData.budget) {
            campaignFormData.budget = ''
            campaignFormData.budgetType = 'na'
          } else {
            campaignFormData.budgetType = 'budget'
          }
        }
      }
      //to check the preview type either for new campaign or alreaduy existed campaign
      let url = ''
      let urlArray = match.url.split('/')
      if (urlArray[3] == 'edit') {
        url = 'edit'
      } else if (urlArray[3] == 'create') {
        url = 'create'
      }
      let merchantIds = this.state.merchantIds
      console.log('merchantiiiiiiddddd--------------------------------')
      if (!campaignForEdit && !(location && location.isPreview)) {
        console.log('merchantiiiiiiddddd--------------------------------')
        if (merchantIds) {
          merchantIds.forEach((item, index) => {
            selectedMerchants.push(item.value)
          })
          await this.setState({ selectedMerchants: selectedMerchants })
        }

        //if preview campaign is from edit it enters into this loop
      } else if ((campaignForEdit) && url == 'edit') {
        if (campaignForEdit) {
          let campaignMerchantIds = campaignForEdit.merchantIds
          let matchedMerchants = []
          if (campaignMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < campaignMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i]);
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }

        //if preview campaign is from create it enters into this loop
      } else if (((location && location.isPreview)) && url == 'edit') {
        if (previewData && previewData.name) {
          let campaignMerchantIds = previewData.merchantIds
          let matchedMerchants = []
          if (campaignMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < campaignMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == campaignMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i])
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }

        //if preview campaign is from create it enters into this loop
      } else if (location && location.isPreview && url == 'create') {
        //for datahandling for backeend we check here
        if (previewData.type && previewData.type == 'All') {
          if (merchantIds) {
            merchantIds.forEach((item, index) => {
              selectedMerchants.push(item.value)
            })
            await this.setState({ selectedMerchants: selectedMerchants })
          }
          // 
        } else {
          let previewMerchantIds = previewData.merchantIds
          let matchedMerchants = []
          if (previewMerchantIds) {
            for (let i = 0; i < merchantIds.length; i++) {
              for (let j = 0; j < previewMerchantIds.length; j++) {
                if (merchantIds[i].merchantId == previewMerchantIds[j]) {
                  matchedMerchants.push(merchantIds[i])
                }
              }
            }
            this.setState({ selectedMerchants: matchedMerchants })
          }
        }
      }
    } else {
      history.push('/login')
    }
    this.setState({ isLoading: false })
    console.log('endmerchants-------------------',this.state.merchantIds)

  }

  componentDidUpdate(prevProps) {
    let {
      setCampaignForEdit,
      user,
      currentCampaignCount,
      getProjectionsForUser
    } = this.props
    if (this.props.match.url !== prevProps.match.url) {
      setCampaignForEdit(null)
    }
    if (!this.props.campaignForEdit
      && (prevProps.campaignForEdit !== this.props.campaignForEdit
        || prevProps.currentCampaignCount !== currentCampaignCount)) {
      let m = moment()
      let campaignFormData = { ...CampaignFormInitialData, campaignName: `${user && user.entity && user.entity.name ? user.entity.name : ''}${m.format('YYMM')}${currentCampaignCount + 1}` }
    }
  }

  componentWillUnmount() {
    let { setCampaignForEdit } = this.props
    setCampaignForEdit(null)
  }

  onChangeListener = (oldCampaignFormData, newCampaignFormData) => {
    if (oldCampaignFormData.values !== newCampaignFormData.values)
      this.setState({ campaignFormData: newCampaignFormData.values })
  }

  onCampaignSummaryData = (data) => {
    this.campaignSummaryData = data
  }

  formikRef = (e) => {
    this.formik = e
  }

  submitCreateCampaign = (type, summaryData) => {
    if (this.formik)
      this.formik.submitForm()
    this.setState({ submitType: type, summaryValues: summaryData })
  }

  getDataForCampaignSubmit = (campaignFormData) => {
    let {
      customerType,
      lastTransaction,
      name,
      start_date,
      start_time,
      end_time,
      weekDays,
      successFee,
      discountType,
      discountPercent,
      discountAmount,
      discountValue,
      discountSpreadAmount,
      discountSpreadValue,
      brand_id,
      brandId,
      suggestions,
      spread_avg_spend,
      recurring
    } = campaignFormData
    let { campaignForEdit } = this.props
    let currentBid = {
      name: name,
      customerType: customerType === "existing" ? 'existing' : customerType === "all" ? 'all' : 'new',
      openingTime: new Date(moment(start_date).startOf('month').toISOString()),
      closingTime: new Date(moment(start_date).endOf('month').toISOString()),
      name,
      campaign_status: 'future',
      successFee: parseInt(successFee),
      weekDays: this.state.weekDaysArray,
      discountType,
      suggestions,
      start_time: start_time,
      end_time: end_time,
      recurring: recurring
    }
    switch (discountType) {
      case 'dp': {
        currentBid['discountPercent'] = parseInt(discountPercent)
        break;
      }
      case 'dv': {
        currentBid['discountAmount'] = parseFloat(discountAmount)
        currentBid['discountValue'] = parseFloat(discountValue)
        break
      }
      case 'ss': {
        currentBid['discountSpreadAmount'] = parseInt(discountSpreadAmount)
        currentBid['discountSpreadValue'] = parseInt(discountSpreadValue)
        currentBid['spreadAvgSpend'] = parseFloat(spread_avg_spend)
        break
      }
      default: { }
    }
    if (campaignForEdit) {
      currentBid._id = campaignForEdit._id
    }
    if (customerType === 'existing') {
      currentBid.lastTransaction = parseInt(lastTransaction);
    } else {
      currentBid.lastTransaction = 'NULL';
    }
    if (brand_id)
      currentBid.brand_id = brand_id.id
    if (brandId)
      currentBid.brandId = brandId
    if (discountType)
      currentBid.discountType = discountType
    return currentBid;
  }

  getNotificationMessage = data => {
    let { campaignForEdit } = this.props;
    if (data._id) {
      return `Campaign "${campaignForEdit.name}" updated successfully`
    } else {
      return `Campaign "${data.name}" has been created successfully`
    }
  }

  handleSuccessRedirect = async () => {

    let {
      history,
      user
    } = this.props
    await this.setState({
      isCampaignCreated: true
    })
    history.push('/user/campaigns/future');
  }

  //handle action to show preview or direct submit
  handleAction = (values) => {
    let { submitType, isApproved } = this.state
    // if (!isApproved) {
    if (submitType == 'create') {
      this.setShowTCModal(values)
    } else {
      this.previewValues(values)
    }
    // } else {
    //   this.setState({ branchesPendingModal: true })
    // }

  }

  onSubmit = (values, actions) => {
    let {
      createCampaign,
      successNotification,
      errorNotification,
      editCampaign, user } = this.props;
    let { summaryValues } = this.state
    let campaignData = values;
    if (document.getElementById("recurring")) {
      campaignData['recurring'] = document.getElementById("recurring").checked
    }
    let campaignSummaryData = this.campaignSummaryData;
    let data = this.getDataForCampaignSubmit(campaignData, campaignSummaryData)
    let executeAction = data._id ? editCampaign : createCampaign;
    if (values && values.type) {
      data.type = values.type
    } else if (values && values.merchantIds) {
      data.merchantIds = values.merchantIds
    }
    data = { ...summaryValues, ...data }
    if (data._id &&
      !data.suggestions) {
      delete data['suggestions'];

    }
    delete data['campaign_status'];
    executeAction(data, (res, type) => {
      if (type == 'success') {
        if (!this.props.user.isTAndCAccepted) {
          this.props.updateUser({
            isTAndCAccepted: true,
            brandId: user.brandId
          })
        }
        this.handleSuccessRedirect()
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = this.getNotificationMessage(data)
          successNotification(notification)
        })
        return;
      }
      let error = res ? res : ''
      setTimeout(async () => {
        if (error !== 'Session expired, please login again.') {
          let notification = { ...notificationOpts }
          notification.message = `Error : ${error}`
          errorNotification(notification)
        } else {
          await this.setState({ sessionExpiryModal: true, branchRoute: false })
        }

      })
    })
  }

  setShowTCModal = (values) => {
    let { user } = this.props;
    let percentageData = this && this.campaignSummaryRef && this.campaignSummaryRef.state && this.campaignSummaryRef.state.percentageData ? this.campaignFormRef.state.percentageData : {}
    if (values && values.name) {
      this.setState({ formValues: values })
    }
    if (user && user.isTAndCAccepted) {
      this.onSubmit(values);
    } else {
      this.setState({ showTCModal: true })
    }
    // if(loggedIncredentials && loggedIncredentials)

  }

  handleTCResponse = () => {
    let { formValues } = this.state
    this.setState({ showTCModal: false, showPreviewModal: false }, () => {
      this.onSubmit(formValues)

    })
  }

  onCloseTCModal = () => {
    this.setState({ showTCModal: false });
  }

  onClosePreviewModal = () => {
    this.setState({ showPreviewModal: false });
  }

  sessionExpired = () => {
    DeleteSessionValue('user')
    this.setState({ loginRedirect: true, sessionExpired: true })
    setTimeout(() => this.setState({ loginRedirect: true, sessionExpired: true }), 1000)
  }

  onSelect = (selectedItem) => {
    let merchantList = this.props.merchantIds;
    console.log(selectedItem, "selectedItem")
    this.setState({ selectedMerchants: selectedItem, isMultiSelected: true })
  }

  handleWeekDayArray = async (weekIndex, label) => {
    let weekDays = this.state.weekDaysArray;
    let selectedDays = this.state.selectedDays;
    let selectedDaysFlag = false, sindex = -1;
    for (let i = 0; i < selectedDays.length; i++) {
      selectedDaysFlag = false;
      if (selectedDays[i]['label'] === label) {
        selectedDaysFlag = true;
        sindex = i;
        break;
      } else {
        selectedDaysFlag = false;
      }
    }
    weekDays[weekIndex]['selected'] = !weekDays[weekIndex]['selected'];
    if (!selectedDaysFlag) {
      selectedDays.push({
        "label": label,
        "value": label
      })
    } else {
      if (sindex != -1) {
        selectedDays.splice(sindex, 1)
      }
    }
    await this.setState({ weekDaysArray: weekDays, selectedDays: selectedDays })

  }
  previewValues = async (values) => {
    let { setPreview } = this.props
    let { summaryValues } = this.state
    values = { ...summaryValues, ...values }
    values['weekDays'] = this.state.weekDaysArray;
    setPreview(values)
    await this.setState({ previewRoute: true })
  }
  showModal = (location) => {
    const { isCampaignCreated } = this.state;
    const { history } = this.props;
    if (location && !isCampaignCreated) {
      this.setState({
        modalVisible: true,
        lastLocation: location,
      })
    }
  }

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation, isCampaignCreated } = this.state;
    const { shouldBlockNavigation } = this.props;
    console.log('show----------',confirmedNavigation)
    console.log('show------',isCampaignCreated)
    console.log(nextLocation)
    if (!confirmedNavigation && !isCampaignCreated) {
      this.showModal(nextLocation)
      return false
    }
    return true
  }
  closeModal = () => {
    this.setState({
      modalVisible: false
    })
  }
  handleConfirmNavigationClick = () => {
    const { history } = this.props
    const { lastLocation } = this.state;
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        // Navigate to the previous blocked location with your navigate function     
        history.push(lastLocation.pathname)
      })
    }
  }
  render() {
    let { projections, campaignForEdit, user, settings, brands } = this.props;
    let { sessionExpired, loginRedirect, languageData, isLoading, isFirstLoginModal, branchRoute } = this.state
    let headingLabel = 'Create'
    const { when } = this.props
    const { modalVisible, lastLocation } = this.state
    if (campaignForEdit)
      headingLabel = 'Edit'
    return (
      <div>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation}
        />
        {loginRedirect ? <Redirect to='/' /> : null}
        {this.state.previewRoute ? <Redirect to='/user/campaigns/create/preview' /> : null}
        {this.state.trainComponent ? <Redirect to='/user/traincomponent' /> : null}

        {branchRoute ? <Redirect to='/user/branches' /> : null}
        <Loader loader={isLoading} />
        <div className="create-header">
          <div className="heading">
            <div className="row mb-4">
              <div className="col-lg-6  col-md-12  d-flex align-items-center">
                <h2 className="m-0">
                  <span>
                    <Icon className="heading-icon">stars</Icon>
                  </span>
                  {headingLabel}&nbsp;
                  {languageData && languageData['campaign'] ? languageData['campaign'] : 'Campaign'}
                </h2>
              </div>
              {
                user.email === 'brandwithbarclays@test.com'
                  ? (
                    <div style={{ textAlign: 'right' }} className="col-md-4 offset-md-4">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Barclaycard_Logo.svg/1280px-Barclaycard_Logo.svg.png"
                        style={{ width: '150px' }} alt="Barclaycard_Logo" />
                    </div>
                  ) : ''
              }
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-lg-6 col-12">
            <div className="card pt-1 mb-0 pb-0 campagnCard">
              <div className="card-body campaignBody">
                <CampaignForm
                  onChangeListener={this.onChangeListener}
                  formikRef={this.formikRef}
                  setShowTCModal={this.setShowTCModal}
                  initialValues={this.state.campaignFormData}
                  enableReinitialize={true}
                  title={headingLabel}
                  settingsState={this.state.settingsState}
                  merchantIds={this.state.merchantIds}
                  onRef={(ref) => this.campaignFormRef = ref}
                  formType={this.state.formType}
                  merchantsValid={this.state.selectedMerchants.length ? false : true}
                  documents={this.state.documents}
                  languageData={languageData}
                  onSelect={this.onSelect}
                  merchantList={this.state.selectedMerchants}
                  onSubmit={this.handleAction}
                  weekDaysArray={this.state.weekDaysArray}
                  handleWeekDayArray={this.handleWeekDayArray}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 pb-5">
            <CampaignSummary
              onRef={(ref) => this.campaignSummaryRef = ref}
              campaignSummary={this.state.campaignFormData}
              onCampaignSummaryData={this.onCampaignSummaryData}
              projections={projections}
              languageData={languageData}
              title={headingLabel}
              setShowTCModal={this.setShowTCModal}
              values={this && this.campaignFormRef && this.campaignFormRef.state && this.campaignFormRef.state.values ? this.campaignFormRef.state.values : {}}
              selectedMerchants={this.state.selectedMerchants}
              totalMerchants={this.state.merchantIds}
              previewValues={this.previewValues}
              onSubmit={this.submitCreateCampaign}
              campaignDelay={settings && settings.campaignOverviewDelay ? settings.campaignOverviewDelay : 5.75}
              isApproved={this.state.isApproved}
              formType={this.state.formType}
              selectedDays={this.state.selectedDays}
              brands={brands}
              brandData={this.state.brandData}
            />

          </div>
        </div>


           <ModalComponent
            close={null} // Set to null to remove the close (X) button
            callback={this.handleTCResponse}
            show={this.state.showTCModal}
            title={""}
            size="lg"
            message={<TnCComponent />}
            action={"Accept"}
            showCancelButton={false}  // Hide the Cancel button
          />
        <ModalComponent close={e => this.setState({ branchesPendingModal: false })}
          callback={e => this.setState({ branchRoute: true })}
          show={this.state.branchesPendingModal}
          title={"Message"}
          // size="lg"
          message={<div>
            <h5 className='text-center'>You have pending in branches <span className='text-success font-weight-bold'>Approval</span> / <span className='text-danger font-weight-bold'>Reject</span>,</h5> <h5 className='text-center'>Please <span className='text-success font-weight-bold'>Approval</span> / <span className='text-danger font-weight-bold'>Reject</span> before creating campaign </h5>
          </div>}
          action={"Go to Branches"}

        />
        <SessionExpiryModal
          isOpen={this.state.sessionExpiryModal}
        />
        {/* to show no branches modal  */}
        <ModalComponent
          close={e => this.setState({ isFirstLoginModal: false })}
          show={isFirstLoginModal}
          title='Alert'
          message={'You have no Branches to create campaign'}
          action='Add Branch'
          callback={e => this.setState({ branchRoute: true })}
        />
        <ModalComponent
          close={this.closeModal}
          show={modalVisible}
          overrideCancelText='Stay on this page'
          title='Alert'
          message={'Form data will be lost if you move away from this page'}
          action='Leave this page'
          callback={this.handleConfirmNavigationClick}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    campaignsData: state.campaigns,
    user: state.user,
    campaignForEdit: state.campaigns.campaignForEdit,
    currentCampaignCount: state.campaigns.currentCampaignCount,
    projections: state.selectEntities.projections,
    documents: state.documents.items,
    previewData: state.selectEntities.previewData,
    settings: state.selectEntities.settingsData,
    brands: state.selectEntities.brands,
  }),
  dispatch => ({
    loadBrandsData: bindActionCreators(loadBrandData, dispatch),
    loadSettingsData: bindActionCreators(setSettingsData, dispatch),
    getProjectionsForUser: bindActionCreators(getProjectionsForUserActionTrain, dispatch),
    updateUser: bindActionCreators(updateUserProfileAction, dispatch),
    saveCsv: bindActionCreators(saveCsvAction, dispatch),
    saveInitialValues: bindActionCreators(saveinitialValuesAction, dispatch),
    createCampaign: bindActionCreators(createCampaignAction, dispatch),
    editCampaign: bindActionCreators(editCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    getCurrentCampaignCount: bindActionCreators(getCurrentCampaignCountAction, dispatch),
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch),
    getEntityData: bindActionCreators(getEntityAction('createCampaign'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    getDetails: bindActionCreators(getEntityDetails, dispatch),
    getCampaignName: bindActionCreators(getCampaignNameAction, dispatch),
    getManageCreative: bindActionCreators(getManageCreativeAction('manageCreative'), dispatch),
    setPreview: bindActionCreators(setPreviewDataAction, dispatch),
    getSettingsData: bindActionCreators(settingsAction, dispatch),
    clearTemplateData: bindActionCreators(clearCampaignTemplateData, dispatch),
    setTabs: bindActionCreators(saveTabData, dispatch),
  })
)(CreateCampaign);



import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box, InputAdornment, Typography, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { listLoyaltyPoints, updateLoyaltyPoints, deleteLoyaltyPoints } from '../../services/rewards/rewardsApi';
import moment from 'moment';
import DayPicker from './day-picker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const ITEMS_PER_PAGE = 8;

const FetchOffersPage = () => {
    // Form states
    const [pan, setPan] = useState('');
    const [offer_type, setOfferType] = useState('discount');
    const [offer_value, setOfferValue] = useState('');
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [kmid, setKmid] = useState('DISC123');
    const [loyaltyPointsList, setLoyaltyPointsList] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [min_txn_amount, setMin_txn_amount] = useState('');
    const [max_offer_amount, setMax_offer_amount] = useState('');
    const [id, setId] = useState('');
    const [isPanDisabled, setIsPanDisabled] = useState(false);
    const [max_txns, setMax_txns] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    // const [selectedDays, setSelectedDays] = useState(Array.isArray(initialSelectedDays) ? initialSelectedDays : []);
    const [txnFrequency, setTxnFrequency] = useState("");
    const [panError, setPanError] = useState(false);
    const [offerValueError, setOfferValueError] = useState(false);
    const [minTxnAmountError, setMinTxnAmountError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [initialSelectedDays, setInitialSelectedDays] = useState({});
    useEffect(() => {
        listLoyaltyPoints()
            .then((response) => {
                setLoyaltyPointsList(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }, []);
    const paginatedList = loyaltyPointsList.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
    );

    const handleNextPage = () => {
        if ((currentPage + 1) * ITEMS_PER_PAGE < loyaltyPointsList.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handleAddPoints = () => {
        if (validateFields()) {
            const newEntry = {
                pan,
                kmid,
                offer_type,
                offer_value: Number(offer_value),
                min_txn_amount: Number(min_txn_amount),
                max_offer_amount: Number(max_offer_amount),
                from_date: formatToISOString(from_date),
                to_date: formatToISOString(to_date),
                txn_frequency: Number(txnFrequency),
                selected_days: selectedTimeSlots,
                max_redemptions: max_txns,
                mode: "insert"
            };
            updateLoyaltyPoints(newEntry)
                .then(() => {
                    setMessage('Offer added successfully!');
                    clearForm();
                    setOpenModal(false);
                    window.location.reload();
                })
                .catch((err) => {
                    console.error('Error adding points:', err);
                });
        }
    };
    const openAddModal = () => {
        clearForm();
        setIsPanDisabled(false)
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        clearForm();
    };
    const formatToISOString = (date) => {
        // Extract the local date components to construct the ISO string manually
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0'); // Local hours
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Construct the exact ISO string format in local time (without Z for UTC)
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    const handleUpdatePoints = () => {

        if (validateFields()) {
            const updatedEntry = {
                id,
                pan,
                kmid,
                offer_type,
                offer_value: Number(offer_value),
                min_txn_amount: Number(min_txn_amount),
                max_offer_amount: Number(max_offer_amount),
                from_date: formatToISOString(from_date),
                to_date: formatToISOString(to_date),
                txn_frequency: Number(txnFrequency),
                selected_days: selectedTimeSlots,
                max_redemptions: max_txns,
                mode: "update",
            };
            updateLoyaltyPoints(updatedEntry)
                .then(() => {
                    setMessage('Offer updated successfully!');
                    clearForm();
                    setOpenModal(false);
                    setEditingIndex(null);
                    window.location.reload();
                })
                .catch((err) => {
                    console.error('Error updating points:', err);
                });
        }
    };

    const clearForm = () => {
        setPan('');
        setOfferValue('');
        setMin_txn_amount('');
        setMax_offer_amount('');
        setFromDate(null);
        setToDate(null);
        setEditingIndex(null);
        setMax_txns('');
        setSelectedDays([])
        setSelectedTimeSlots([])
        setInitialSelectedDays({})
    };

    // const modalStyle = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: '90%',
    //     maxWidth: '1200px',
    //     maxHeight: '90vh',
    //     overflowY: 'auto',
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    // };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: '90%', // Adjust to desired width
        maxWidth: '1200px', // Maximum width for desktop
        maxHeight: '90vh', // Maximum height for vertical overflow
        overflowY: 'auto', // Enable vertical scrolling
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleFromDateChange = (newValue) => {
        setFromDate(newValue ? new Date(newValue) : null);
        setFromDateError(!newValue);
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue ? new Date(newValue) : null);
        setToDateError(!newValue);
    };

    const validateFields = () => {
        let valid = true;
        if (pan === '') {
            setPanError(true);
            valid = false;
        } else {
            setPanError(false);
        }
        if (offer_value === '') {
            setOfferValueError(true);
            valid = false;
        } else {
            setOfferValueError(false);
        }
        if (!from_date) {
            setFromDateError(true);
            valid = false;
        } else {
            setFromDateError(false);
        }
        if (!to_date) {
            setToDateError(true);
            valid = false;
        } else {
            setToDateError(false);
        }
        return valid;
    };


    const handleEdit = (index) => {
        setIsPanDisabled(true);
        const selectedEntry = loyaltyPointsList[index];
        setId(selectedEntry.id);
        setPan(selectedEntry.pan);
        setKmid(selectedEntry.kmid);
        setOfferType(selectedEntry.offer_type);
        setOfferValue(selectedEntry.offer_value);
        setTxnFrequency(selectedEntry.txn_frequency);
        setMin_txn_amount(selectedEntry.min_txn_amount);
        setMax_offer_amount(selectedEntry.max_offer_amount);
        setFromDate(new Date(selectedEntry.from_date));
        setToDate(new Date(selectedEntry.to_date));
        setMax_txns(selectedEntry.max_redemptions);

        // Extract and set selected days
        const days = selectedEntry.selected_days.map(dayObj => dayObj.day);
        setSelectedDays(days);
        const timeSlotsArray = selectedEntry.selected_days
        setSelectedTimeSlots(timeSlotsArray);
        setEditingIndex(index);
        setOpenModal(true);
        setMessage(null);
        console.log("Handle Edit    :    " + JSON.stringify(days) + "   :   " + JSON.stringify(timeSlotsArray))
    };


    const handleSelectedDaysChange = (daysOutput) => {
        setSelectedDays(daysOutput)
    };
    const handleTxnFrequencyChange = (event) => {
        setTxnFrequency(event.target.value);
    };
    const handleDeletePoints = (id) => {
        deleteLoyaltyPoints({ id })
            .then(() => {
                // setLoyaltyPointsList(loyaltyPointsList.filter((item) => item.pan !== pan));
                setMessage('Offer deleted successfully!');
                window.location.reload()
            })
            .catch((err) => {
                console.error('Error deleting points:', err);
            });
    };
    return (
        <div className="form-container">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <h2>Offers Management</h2>
                <Button variant="contained" color="primary" onClick={() => openAddModal(true)}>
                    Add Offer
                </Button>
            </Box>

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={modalStyle}>
                    <h2>{editingIndex === null ? 'Add Offer' : 'Edit Offer'}</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                fullWidth
                                disabled={isPanDisabled}
                                margin="normal"
                                label="PAN"
                                required
                                value={pan}
                                error={panError}
                                helperText={panError ? 'PAN is required' : ''}
                                onChange={(e) => setPan(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="KMID"
                                value={kmid}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Offer Type"
                                value={offer_type}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Offer Value (%)"
                                required
                                value={offer_value}
                                error={offerValueError}
                                helperText={offerValueError ? 'Offer Value is required' : ''}
                                onChange={(e) => setOfferValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="number"
                                margin="normal"
                                label="Min Txn Amount"
                                value={min_txn_amount}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                onChange={(e) => setMin_txn_amount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Max Offer Amount"
                                value={max_offer_amount}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                onChange={(e) => setMax_offer_amount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="number"
                                margin="normal"
                                label="Max Redemptions"
                                value={max_txns}
                                onChange={(e) => setMax_txns(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Transaction Frequency"
                                type="number"
                                value={txnFrequency}
                                onChange={handleTxnFrequencyChange}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 0 }}
                            />
                        </Grid>
                    </Grid>
                    <div style={{ paddingTop: '10px' }}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: 'row' }}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        label="From Date & Time"
                                        ampm={false}
                                        value={from_date}
                                        onChange={handleFromDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required={true}
                                                onError={true}
                                                error
                                                helperText={fromDateError ? 'From Date is required' : ''}
                                            />
                                        )}
                                    />
                                    {fromDateError && (
                                        <Typography variant="caption" color="error" style={{ marginTop: '5px' }}>
                                            From Date is required
                                        </Typography>
                                    )}
                                </DemoContainer>

                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        ampm={false}
                                        label="To Date & Time"
                                        value={to_date}
                                        required={true}
                                        onChange={handleToDateChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required={true}
                                                onError={true}
                                                error
                                                helperText={toDateError ? 'To Date is required' : ''}
                                            />
                                        )}
                                    />
                                    {toDateError && (
                                        <Typography variant="caption" color="error" style={{ marginTop: '5px' }}>
                                            To Date is required
                                        </Typography>
                                    )}
                                </DemoContainer>

                            </Box>
                        </LocalizationProvider> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: 'row' }}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        label="From Date & Time"
                                        ampm={false}
                                        value={from_date}
                                        views={['year', 'day', 'hours', 'minutes', 'seconds']}
                                        onChange={handleFromDateChange}
                                        minutesStep={1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required={true}
                                                onError={true}
                                                error
                                                helperText={fromDateError ? 'From Date is required' : ''}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                                {fromDateError && (
                                    <Typography variant="caption" color="error">
                                        From Date is required
                                    </Typography>
                                )}
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        label="To Date & Time"
                                        value={to_date}
                                        ampm={false}
                                        views={['year', 'day', 'hours', 'minutes', 'seconds']}
                                        required={true}
                                        onChange={handleToDateChange}
                                        minutesStep={1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required={true}
                                                onError={true}
                                                error
                                                helperText={toDateError ? 'To Date is required' : ''}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                                {toDateError && (
                                    <Typography variant="caption" color="error">
                                        To Date is required
                                    </Typography>
                                )}
                            </Box>
                        </LocalizationProvider>
                    </div>
                    <DayPicker
                        selectedDays={selectedDays}
                        setSelectedDays={setSelectedDays}
                        selectedTimeSlots={selectedTimeSlots}
                        setSelectedTimeSlots={setSelectedTimeSlots}
                        onSelectedDaysChange={handleSelectedDaysChange}
                        initialSelectedDays={initialSelectedDays}
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => setOpenModal(false)} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={editingIndex === null ? handleAddPoints : handleUpdatePoints}
                            color="primary"
                            variant="contained"
                        >
                            {editingIndex === null ? 'Submit' : 'Update'}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {message && <p className="text-success">{message}</p>}
            {loading && <p>Loading...</p>}

            {/* Loyalty Points List */}
            {
                loyaltyPointsList.length > 0 && (
                    <div>
                        {/* <table className="table table-bordered mt-4">
                            <thead>
                                <tr>
                                    <th>PAN</th>
                                    <th>KMID</th>
                                    <th>Offer Type</th>
                                    <th>Offer Value</th>
                                    <th>Min Txn Amount</th>
                                    <th>Max Offer Amount</th>
                                    <th>From Date</th>
                                    <th>To Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.pan}</td>
                                        <td>{item.kmid}</td>
                                        <td>{item.offer_type}</td>
                                        <td>{item.offer_value}%</td>
                                        <td>{item.min_txn_amount} £</td>
                                        <td>{item.max_offer_amount} £</td>
                                        <td>{moment(item.from_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td>{moment(item.to_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td>
                                            <button className="btn btn-warning mr-2" onClick={() => handleEdit(index)}>
                                                Edit
                                            </button>
                                            <button className="btn btn-danger" onClick={() => handleDeletePoints(item.id)}>
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
              <div className="table-responsive">
  <table className="table table-bordered mt-4">
    <thead>
      <tr>
        <th>PAN</th>
        <th>KMID</th>
        <th>Offer Type</th>
        <th>Offer Value (%)</th>
        <th>Min Txn Amount (£)</th>
        <th>Max Offer Amount (£)</th>
        <th>From Date</th>
        <th>To Date</th>
        <th>Txn Frequency</th>
        <th>Selected Days</th>
        <th>Max Redemptions</th>
        <th class="actions-column">Actions</th>
      </tr>
    </thead>
    <tbody>
      {paginatedList.map((item, index) => (
        <tr key={index}>
          <td>{item.pan}</td>
          <td>{item.kmid}</td>
          <td>{item.offer_type}</td>
          <td>{item.offer_value}</td>
          <td>{item.min_txn_amount}</td>
          <td>{item.max_offer_amount}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <span style={{ color: 'black' }}>
              {moment(item.from_date).format('YYYY-MM-DD')}
            </span>
            <br />
            <span style={{  color: 'black',fontWeight:'bold' }}>
              {moment(item.from_date).format('hh:mm:ss')}
            </span>
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <span style={{ color: 'black' }}>
              {moment(item.to_date).format('YYYY-MM-DD')}
            </span>
            <br />
            <span style={{ color: 'black',fontWeight:'bold' }}>
              {moment(item.to_date).format('hh:mm:ss')}
            </span>
          </td>
          <td>{item.txn_frequency}</td>
          <td>
            {item.selected_days.length > 0 ? (
              item.selected_days.map((day, i) => (
                <div key={i}>
                  {day.day}: {day.start_time || 'N/A'} - {day.end_time || 'N/A'}
                </div>
              ))
            ) : (
              'N/A'
            )}
          </td>
          <td>{item.max_redemptions}</td>
          <td class="actions-column">
            <button className="btn btn-warning mr-2" onClick={() => handleEdit(index)}>
              Edit
            </button>
            <button className="btn btn-danger" onClick={() => handleDeletePoints(item.id)}>
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>




                        {/* Pagination Controls */}
                        <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                            <button className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 0}>
                                Previous
                            </button>
                            <button className="btn btn-secondary" onClick={handleNextPage} disabled={(currentPage + 1) * ITEMS_PER_PAGE >= loyaltyPointsList.length}>
                                Next
                            </button>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default FetchOffersPage;

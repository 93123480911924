import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import { useLocation } from 'react-router';

class Nav extends Component {
  state = {
    rewardsMenuOpen: false,
    // isPathActive : (path) => location.pathname === path,

  };
  
  // Helper function to check if the path is active
  isPathActive(path) {
    // return this.props.location.pathname.startsWith(path);
    return this.props.location.pathname === path;

  }


  componentDidMount() {
    if (this.isPathActive('/user/rewards/instant-offers') || this.isPathActive('/user/rewards/loyalty-points'))
      this.setState({ rewardsMenuOpen: true })
    if (this.isPathActive('/user/campaigns'))
      this.setState({ campaignMenuOpen: true })
  }

  getNavForBrand = () => {
    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard-v2')
            })
          }
        >
          <Link to="/user/dashboard-v2">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard-V2</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/groupbrand')
            })
          }
        >
          <Link to={{ pathname: "/user/list/groupbrand", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>groups</Icon></i>
            <p>Groups</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brands')
            })
          }
        >
          <Link to={{ pathname: "/user/list/brands", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Brands</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/brandactioncenter')
            })
          }
        >
          <Link to={{ pathname: "/user/brandactioncenter", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Brand Action Center</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/createticket')
            })
          }
        >
          <Link to={{ pathname: "/user/createticket", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Tickets</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/employees')
            })
          }
        >
          <Link to="/user/list/employees">
            <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
            <p>Employees</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/campaigntemplates')
            })
          }
        >
          <Link to="/user/list/campaigntemplates">
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Campaign Templates</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brandactivity')
            })
          }
        >
          <Link to="/user/list/brandactivity">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Brand Activity</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/activities')
            })
          }
        >
          <Link to="/user/list/activities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Activities</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/onboardactivities')
            })
          }
        >
          <Link to="/user/list/onboardactivities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Onboarding Activities</p>
          </Link>
        </li>

        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/ingestionLogs')
            })
          }
        >
          <Link to="/user/list/ingestionLogs">
            <i><Icon style={{ fontSize: "20px" }}>account_tree</Icon></i>
            <p>Ingestion Logs</p>
          </Link>
        </li>

        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/signupUsers')
            })
          }
        >
          <Link to="/user/list/signupUsers">
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>Signup Users</p>
          </Link>
        </li>

        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              My Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>


        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ rewardsMenuOpen: true })}
            data-toggle="collapse"
          >
            <i>
              <Icon style={{ fontSize: '20px' }}>card_giftcard</Icon>
            </i>
            <p>
              Rewards
              <b className={`caret ${this.state.rewardsMenuOpen ? 'open' : ''} active`}></b>
            </p>
          </button>

          {/* Rewards Menu - Collapsible Section */}
          <Collapse in={this.state.rewardsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                {/* Instant Offers Section */}
                <li className={this.isPathActive('/user/rewards/loyalty-points') ? 'active' : ''}>
                  <Link to="/user/rewards/loyalty-points">
                    <i>
                      <Icon style={{ fontSize: '20px' }}>local_offer</Icon>
                    </i>
                    <p>Loyalty</p>
                  </Link>
                </li>

                {/* Loyalty Points Section */}
                <li className={this.isPathActive('/user/rewards/fetch-offers') ? 'active' : ''}>
                  <Link to="/user/rewards/fetch-offers">
                    <i>
                      <Icon style={{ fontSize: '20px' }}>star</Icon>
                    </i>
                    <p>Discounts</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }

  getNavForBank = () => {
    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brands')
            })
          }
        >
          <Link to={{ pathname: "/user/list/brands", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Brands</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/publisheractioncenter')
            })
          }
        >
          <Link to={{ pathname: "/user/publisheractioncenter", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>pending_actions</Icon></i>
            <p>Action Center</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/campaigns/approval')
            })
          }
        >
          <Link to={{ pathname: "/user/campaigns/approval", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Campaign Approval</p>
          </Link>
        </li>
        <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
          <Link to="/user/campaigns/current">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Current Campaign</p>
          </Link>
        </li>
        <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
          <Link to="/user/campaigns/future">
            <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
            <p>Future Campaigns</p>
          </Link>
        </li>
        <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
          <Link to="/user/campaigns/past">
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Past Campaigns</p>
          </Link>
        </li>
      </ul>
    )
  }

  getNavForSalesAdmin = () => {

    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/list/groupbrand')
            })
          }
        >
          <Link to={{ pathname: "/user/list/groupbrand", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>groups</Icon></i>
            <p>Groups</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brands')
            })
          }
        >
          <Link to={{ pathname: "/user/list/brands", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Brands</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/brandactioncenter')
            })
          }
        >
          <Link to={{ pathname: "/user/brandactioncenter", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>pending_actions</Icon></i>
            <p>Brand Action Center</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/createticket')
            })
          }
        >
          <Link to={{ pathname: "/user/createticket", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Tickets</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brandactivity')
            })
          }
        >
          <Link to="/user/list/brandactivity">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Brand Activity</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/activities')
            })
          }
        >
          <Link to="/user/list/activities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Activities</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/onboardactivities')
            })
          }
        >
          <Link to="/user/list/onboardactivities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Onboarding Activities</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/ingestionLogs')
            })
          }
        >
          <Link to="/user/list/ingestionLogs">
            <i><Icon style={{ fontSize: "20px" }}>account_tree</Icon></i>
            <p>Ingestion Logs</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/signupUsers')
            })
          }
        >
          <Link to="/user/list/signupUsers">
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>Signup Users</p>
          </Link>
        </li>

        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              My Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }

  getNavForOpsAdmin = () => {

    return (
      <ul className="nav flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard')
            })
          }
        >
          <Link to="/user/dashboard">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/dashboard-v2')
            })
          }
        >
          <Link to="/user/dashboard-v2">
            <i><Icon style={{ fontSize: "20px" }}>home</Icon></i>
            <p>Dashboard-V2</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/groupbrand')
            })
          }
        >
          <Link to={{ pathname: "/user/list/groupbrand", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>groups</Icon></i>
            <p>Groups</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brands')
            })
          }
        >
          <Link to={{ pathname: "/user/list/brands", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
            <p>Brands</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/brandactioncenter')
            })
          }
        >
          <Link to={{ pathname: "/user/brandactioncenter", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>pending_actions</Icon></i>
            <p>Brand Action Center</p>
          </Link>
        </li>
        {/* <li
          className={
            classNames({
              active: this.isPathActive('/user/createticket')
            })
          }
        >
          <Link to={{ pathname: "/user/createticket", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>create</Icon></i>
            <p>Tickets</p>
          </Link>
        </li> */}
        <li
          className={
            classNames({
              active: this.isPathActive('/user/publisheractioncenter')
            })
          }
        >
          <Link to={{ pathname: "/user/publisheractioncenter", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>pending_actions</Icon></i>
            <p>Publisher Action Center</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/whitelabelControls')
            })
          }
        >
          <Link to={{ pathname: "/user/whitelabelControls", state: 'brands' }}>
            <i><Icon style={{ fontSize: "20px" }}>pending_actions</Icon></i>
            <p>Whitelabel Controls</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/brandactivity')
            })
          }
        >
          <Link to="/user/list/brandactivity">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Brand Activity</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/activities')
            })
          }
        >
          <Link to="/user/list/activities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Activities</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/onboardactivities')
            })
          }
        >
          <Link to="/user/list/onboardactivities">
            <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
            <p>Onboarding Activities</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/ingestionLogs')
            })
          }
        >
          <Link to="/user/list/ingestionLogs">
            <i><Icon style={{ fontSize: "20px" }}>account_tree</Icon></i>
            <p>Ingestion Logs</p>
          </Link>
        </li>
        <li
          className={
            classNames({
              active: this.isPathActive('/user/list/signupUsers')
            })
          }
        >
          <Link to="/user/list/signupUsers">
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>Signup Users</p>
          </Link>
        </li>

        <li
          className={
            classNames({
              active: this.state.campaignMenuOpen // this.isPathActive('/user/campaigns')
            })
          }
        >
          <button
            onClick={() => this.setState({ campaignMenuOpen: !this.state.campaignMenuOpen })}
            data-toggle="collapse"
          >
            <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
            <p>
              My Campaigns
              <b
                className={
                  classNames('caret', {
                    open: this.state.campaignMenuOpen,
                    active: true
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.campaignMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li className={this.isPathActive('/user/campaigns/current') ? 'active' : ''}>
                  <Link to="/user/campaigns/current">
                    <i><Icon style={{ fontSize: "20px" }}>event_available</Icon></i>
                    <p>Current Campaign</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/future') ? 'active' : ''}>
                  <Link to="/user/campaigns/future">
                    <i><Icon style={{ fontSize: "20px" }}>fast_forward</Icon></i>
                    <p>Future Campaigns</p>
                  </Link>
                </li>
                <li className={this.isPathActive('/user/campaigns/past') ? 'active' : ''}>
                  <Link to="/user/campaigns/past">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Past Campaigns</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    )
  }

  getNavs = () => {
    let { user } = this.props;
    if (user.entityType === 'superAdmin')
      return this.getNavForBrand()
    else if (user.entityType === 'bank')
      return this.getNavForBank()
    else if (user.entityType === 'salesAdmin')
      return this.getNavForSalesAdmin();
    else if (user.entityType === 'opsAdmin')
      return this.getNavForOpsAdmin();
    else
      return '';
  }
  render() {
    return this.getNavs()
  }
}

export default withRouter(Nav);
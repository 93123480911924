import axiosMethodRequest, { getBranchDetails, getDashBoardData, getDetailedInfo } from '../../config/service';
import { GET_EXISTING_BRANCH_LIST } from '../../redux/newBrandBranch/actionTypes';
import { GET_GROUP_LIST, GET_DISTRIBUTORS, GET_UPLOADED_DATE } from '../../redux/newBrandBranch/actionTypes';
import { constructUrl } from '../../utils/helpers';

export const checkBrandExistApiCall = (data) => {
    return dispatch => {
        return axiosMethodRequest('GET', constructUrl("brands/checkBrandExist", data)).then((response) => {
            console.log("Check Brand Exist Api Call   :   " + JSON.stringify(response))
            if (response && response.data && response.data.respCode) {
                if (response.data.branch) {
                    response.data.branch.forEach(element => {
                        if (!element.cuisines_list) {
                            element.cuisines_list = [];
                        } else {
                            let splitData = element.cuisines_list.split(",");
                            element.cuisines_list = [];
                            splitData.forEach(e2 => {
                                e2 = e2.replace(/^\s+|\s+$/g, '');
                                element.cuisines_list.push({ label: e2, value: e2 });
                            });
                        }
                        if (element.whitelabel) {
                            Object.keys(element.whitelabel).forEach(e1 => {
                                if (element.whitelabel[e1].cuisines_list && element.whitelabel[e1].cuisines_list.length !== 0) {
                                    let splitData = element.whitelabel[e1].cuisines_list.split(",");
                                    element.whitelabel[e1].cuisines_list = [];
                                    splitData.forEach(e2 => {
                                        e2 = e2.replace(/^\s+|\s+$/g, '');
                                        element.whitelabel[e1].cuisines_list.push({ label: e2, value: e2 });
                                    });
                                } else {
                                    element.whitelabel[e1].cuisines_list = [];
                                }
                            });
                        }
                    });
                }
                dispatch({
                    type: GET_EXISTING_BRANCH_LIST,
                    payload: { data: response.data, loader: false }
                })
            }
        })
    }
}


export const getGroups = (data) => {
    return dispatch => {
        return axiosMethodRequest('GET', `groupBrand?from=brand`).then((response) => {
            console.log("Get Groups :   " + JSON.stringify(response))
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_GROUP_LIST,
                    payload: { data: response.data.groupBrand }
                })
            }
        })
    }
}


export const getBranchListByBrandIdAction = (brand_id, callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `entity/getAllEntityBranch?`).then((response) => {
            console.log("getBranchListByBrandIdAction     :     "+JSON.stringify(response))
            if (response && response.data && response.data.respCode) {
                callback(response.data.entityList)
            } else {
                callback([])
            }
        })
    }
}

export const getDistributors = (data) => {
    return dispatch => {
        return axiosMethodRequest('GET', `distributors`).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_DISTRIBUTORS,
                    payload: { data: response.data.distributors }
                })
            }
        })
    }
}

export const getAllDistributor = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `distributors/getAllDistributor`).then((response) => {
            if (response && response.data && response.data.respCode) {
                callback(response.data.distributors);
            }
        })
    }
}

export const getUploadedDate = (body) => {
    return dispatch => {
        return axiosMethodRequest('POST', 'ingestionLogs/getUploadedDate', body).then((response) => {
            if (response && response.data && response.data.respCode) {
                let formatDate = {
                    uploadedDate: response.data.ingestionLogDate.uploadedDate,
                    next_ingestion_date: response.data.ingestionLogDate.next_ingestion_date,
                    last_trans_date: response.data.ingestionLogDate.last_trans_date
                }
                dispatch({
                    type: GET_UPLOADED_DATE,
                    payload: { data: formatDate, ingestionLogList: response.data.ingestionLogList }
                })
            }
        });
    }
}

export const onboardUserAfterPublisherSubmit = (body, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', 'auth/onboardUserAfterPublisherSubmit', body).then((response) => {
            callBack(response);
        });
    }
}

export const sendCampaignCreatedEmail = (body, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', 'auth/sendCampaignCreatedEmail', body).then((response) => {
            callBack(response);
        });
    }
}

export const getDistributorListAction = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `distributors`).then((response) => {
            if (response && response.data && response.data.respCode) {
                callback(response.data.distributors);
            } else {
                callback([]);
            }
        })
    }
}

export const getAllBrandListAction = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `brands/getAllBrandList`).then((response) => {
            if (response && response.data && response.data.respCode) {
                callback(response.data.allBrands);
            } else {
                callback([]);
            }
        })
    }
}

export const getWhitelabels = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `brands/getWhitelabels`).then((response) => {
            if (response && response.data && response.data.respCode) {
                response.data.whitelabels.forEach(element => {
                    element.label = element.partnerName;
                    element.value = element.partnerSerialNo;
                });
                callback(response.data.whitelabels);
            } else {
                callback([]);
            }
        })
    }
}

export const getWhitelabelsData = (callback) => {
        return axiosMethodRequest('GET', `brands/getWhitelabels`).then((response) => {
            console.log("distributortData    :   1 "+JSON.stringify(response))

            if (response && response.data && response.data.respCode) {
                callback(response.data.whitelabels);
            } else {
                callback([]);
            }
        })
}

// export const getMerchantByBrandIdAction = async(brandId) => {
//     // return dispatch => {
//         let request={
//             brandId:brandId
//         }
//         return axiosMethodRequest('GET', 'merchants/getMerchantByBrandId',request).then((response) => {
//             console.log("getBranchListByBrandIdAction  3  :    "+JSON.stringify(response))
//             if (response && response.data && response.data.respCode) {
//                 // callback(response.data.merchants);
//                 return response.data.merchants
//             } else {
//                 // callback([]);
//                 return []
//             }
//         })
//     // }
// }

export const getMerchantByBrandIdAction = (brandId, callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `merchants/getMerchantByBrandId?brandId=` + brandId).then((response) => {
            if (response && response.data && response.data.respCode) {
                callback(response.data.merchants);
            } else {
                callback([]);
            }
        })
    }
}

// export const getMerchantByBrandIdAction =async (brandId) => {
//     // return dispatch => {
//         return axiosMethodRequest('GET', `merchants/getMerchantByBrandId?brandId=` + brandId).then((response) => {
//             if (response && response.data && response.data.respCode) {
//                 return response.data.merchants
//                 // callback(response.data.merchants);
//             } else {
//                 return []
//                 // callback([]);
//             }
//         })
//     // }
// }

export const getCuisines = (callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', `brands/getCuisines`).then((response) => {
            if (response && response.data && response.data.respCode) {
                callback(response.data.cuisines);
            } else {
                callback([]);
            }
        })
    }
}
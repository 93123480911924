export const getDashboardstate = (state) => {
  return state.dashboard.dashboardData
};

export const getUserstate = (state) => {
  return state.user
};


export const getDashboardLoaderstate = (state) => {
  return state.dashboard.dashboardData.loader
};

export const getGroupBrandListstate = (state) => {
  return state.dashboard.groupBrand
};

export const getParentCampaignState = (state) => {
  return state.dashboard.parentCampaignDetails
};

export const getUploadedDatestate = (state) => {
  return state.dashboard.dashboardData.data
};

export const getOfferTypes = (state) => {
  console.log("Getting Dashboard State   :  1234 "+JSON.stringify(state.dashboard.dashboardData.data.distributor_name))
  return state.dashboard.dashboardData.data
};
